import HighchartsReact from "highcharts-react-official";
import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { darkModeState } from '../atoms/darkModeState';
import Highcharts from 'highcharts'

function BarChartSessionLogs({sessionLogs}) {
    const [chartOptions, setChartOptions] = useState({})
    const isDark = useRecoilValue(darkModeState);

    useEffect(() => {
        if(sessionLogs){
            let dates = []
            let userData = []
            let orgData = []
            sessionLogs.map(session => {
                var date = new Date(session.user_log_timestamp) // some mock date
                var milliseconds = date.getTime();
                dates.push(milliseconds)
                userData.push( Number(session.unique_user_count))
                orgData.push(Number(session.unique_organization_count))
            })
            let lastDate = dates[dates.length - 1];
            setChartOptions({

                chart: {
                    type: 'column',
                    backgroundColor: 'transparent',
                    height:'550px'
                },
                time: {
                    useUTC: false
                },
                title: {
                    text: ''
                },
                credits : { enabled : false},
                accessibility: {
                    announceNewData: {
                        enabled: true
                    }
                },
                xAxis: {
                    type: 'datetime',
                    labels: {
                        format: '{value:%b-%e}',
                        style: {
                            color: isDark ? '#d1d5db':'#4b5563'
                        }
                    },
                },
                yAxis: {
                    title: {
                        text: ''
                    },
                    labels: {
                        style: {
                          color: isDark ? '#d1d5db':'#4b5563'
                        }
                    },
                    lineWidth: 0,
                    minorGridLineWidth: 0,
                    // lineColor: isDark ? '#f9fafb':'#dc2626',
                    // labels: {
                    //     enabled: false
                    // },
                    // min:0,
                    minorTickLength: 0,
                    tickLength: 0,
                    gridLineWidth: 0.3,
                    gridLineColor: isDark ? '#a1a1aa':'#a1a1aa',

                },
                tooltip: {
                    shared: true
                },
                // legend: {
                //     enabled: false
                // },
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                            style: {
                              fontWeight: 500,
                              color: isDark ? '#d1d5db':'#4b5563',
                              fontSize:14,
                            //   format: '{point.y:.0f}'
                          }
                        },
                        pointStart: lastDate,
                        pointInterval: 24 * 3600 * 1000 // one day
                    }
                },

                // tooltip: {
                //     headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                //     pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><br/>'
                // },

                series: [
                    {
                        name: 'Unique User',
                        data: userData,
                        color: '#38bdf8',
                    },
                    {
                        name: 'Unique Organizations',
                        data: orgData,
                        color: '#818cf8'
                    },
                ],
            })
        }

    }, [sessionLogs, isDark])

  return (
    <div className='mt-2 p-4 bg-slate-50 dark:bg-slate-700 rounded-3xl flex flex-col text-center w-full lg:h-[550px]'>
      <span className={`${isDark? 'text-gray-300':'text-gray-600'} text-xl`}>Logins Sessions</span>
        <HighchartsReact
            // highcharts={HighchartsStock}
            highcharts={Highcharts}
            // constructorType={'stockChart'}
            options={chartOptions}
        />
    </div>
  )
}

export default BarChartSessionLogs