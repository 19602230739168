import CashARRBurnChart from "./CashARRBurnChart"
import RevChurnStackedBarChart from "./RevChurnStackedBarChart"
import CustomerSenseAiBarChart from "./CustomerSenseAiBarChart"
import SenseAiDeploymentChart from "./SenseAiDeploymentChart"
import SummaryKpisComponent from "./SummaryKpisComponent"
import { getData } from "../ApiCalls/DataApis"
import { apiLink } from "../ApiCalls/ApisVariables"
import { useEffect, useState } from "react"
import BarChartSessionLogs from "./BarChartSessionLogs"
import SessionLogsTable from "./SessionLogsTable"
import Map from '../components/Map'
import LocationTable from "../MyCustomersPage/LocationTable"
import CustomerTable from "../MyCustomersPage/CustomerTable"


function MainKpisPage() {
  const [senseAiMonthlyCount, setSenseAiMonthlyCount] = useState([])
  const [customerData, setCustomerData] = useState([])
  const [totalARR, setTotalARR] = useState(null)
  const [chartDates, setChartDates] = useState([])
  const [sessionLogs, setSessionLogs] = useState([])
  const [currentOnlineDevices, setCurrentOnlineDevices] = useState(0)
  const [customerCount, setCustomerCount] = useState(0)
  const [mainMetrics, setMainMetrics] = useState([])
  const [stateData, setStateData] = useState([])
  const [revChurnData, setRevChurnData] = useState([])
  const [revChurnDates, setRevChurnDates] = useState([])

  useEffect(() => { 
    
    getData(apiLink + '/v1/internal/dashboard/data')
    .then(async response => {
      if(response && response.ok ){
        const data = await response.json();
        if(data){
          setCustomerData(data.data)
          let count = Object.keys(data.data).length
          setCustomerCount(count)
          let currentArr = 0
          let totalDevices = 0
          data.data.map(customer => {
            currentArr += Number(customer.senseai_count)*83*12
            totalDevices += Number(customer.senseai_count)
            
          })
          // console.log('currentArr', currentArr)
          // console.log('totalDevices', totalDevices)
          // setTotalARR(currentArr)
        } 
      }
    });

    getData(apiLink + '/v1/internal/dashboard/state_counts')
      .then(async response => {
        if(response && response.ok ){
          const data = await response.json();
          if(data && data.data){
              // console.log('State',data.data)
              setStateData(data.data)
          } 
        }
      });


    getData(apiLink + '/v1/internal/dashboard/main_metrics')
    .then(async response => {
      if(response && response.ok ){
        const data = await response.json();
        if(data && data.data){
          // console.log('main_metrics', data.data)
          setMainMetrics(data.data[0])
          
        } 
      }
    });
    getData(apiLink + '/v1/internal/dashboard/stats')
    .then(async response => {
      if(response && response.ok ){
        const data = await response.json();
        if(data){
          // console.log('setSenseAiMonthlyCount', data.data)
          setSenseAiMonthlyCount(data.data)
          let dateArray = []
          data.data.map(dates => {
            dateArray.push(dates.to_char)
          })
          setChartDates(dateArray)
          let lastMonth = Object.keys(data.data).length - 1
          let currentDevices = data.data[lastMonth].senseai_assigned_count
          let current_arr = Number(currentDevices)*83*12
          setTotalARR(current_arr)
          setCurrentOnlineDevices(data.data[lastMonth].senseai_online_count)

        } 
      }
    });

    getData(apiLink + '/v1/internal/session_logs')
    .then(async response => {
      if(response && response.ok ){
        const data = await response.json();
        if(data && data.data){
          setSessionLogs(data.data)

        } 
      }
    });
    
    getData(apiLink + '/v1/internal/dashboard/revenue_churn')
    .then(async response => {
      if(response && response.ok ){
        const data = await response.json();
        if(data && data.data){
          // console.log('Revenu chrun',data.data)
          let dateArray = []
          data.data.map(dates => {
            const date = new Date(dates.month);
            date.setDate(date.getDate() + 1); //adding a day because it get switching to the month before because its in UTC time and I'm in ET


            const options = { year: 'numeric', month: 'short' };
            const formattedDate = date.toLocaleDateString('en-US', options);
            dateArray.push(formattedDate)
          })
          setRevChurnDates(dateArray)

          setRevChurnData(data.data)
          

        } 
      }
    });

  }, [])

  return (
    <div className={`px-4 sm:px-1 sm:pl-6 sm:pr-3 pt-1 pb-10 max-h-[calc(100vh-5rem)] overflow-scroll scrollbar-hide`}> 
      <div className='flex'>
        {mainMetrics && Object.keys(mainMetrics).length > 0 && 
          <SummaryKpisComponent mainMetrics={mainMetrics} currentOnlineDevices={currentOnlineDevices} customerCount={customerCount} />
        }
      </div>
      <div className='flex flex-col'>
        <div className='w-full'>
            <RevChurnStackedBarChart revChurnDates={revChurnDates} revChurnData={revChurnData}/>
        </div>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-2'>
          <div className=''>
            <CashARRBurnChart chartDates={chartDates} senseAiMonthlyCount={senseAiMonthlyCount}/>
          </div>
          <div className=''>
            <SenseAiDeploymentChart data={senseAiMonthlyCount} />
          </div>
        </div>
        <div className='flex flex-col lg:flex-row mt-2'>
            <div className='flex-1'>
                <Map customerData={customerData} /> 
            </div>
            <div className='mt-2 lg:mt-0 lg:max-w-[500px] 2xl:max-w-[800px]'>
                <LocationTable stateData={stateData} />
            </div>
        </div>


        <div className='flex flex-col-reverse 2xl:flex-row 2xl:space-x-2'>
            <CustomerSenseAiBarChart customerData={customerData} />
            <CustomerTable customerData={customerData} />

        </div>
        <div className='grid grid-cols-1 2xl:grid-cols-10 gap-2 overflow-hidden'> 
          <div className='col-span-1 2xl:col-span-5 overflow-scroll scrollbar-hide order-last lg:order-1'>
            <SessionLogsTable sessionLogs={sessionLogs} />
          </div>
          <div className='col-span-1 2xl:col-span-5 order-first lg:order-last'>
            <BarChartSessionLogs sessionLogs={sessionLogs} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainKpisPage