import React, { useEffect, useState } from 'react'
import { getData } from '../ApiCalls/DataApis';
import { apiLink } from '../ApiCalls/ApisVariables';
import LeadsTable from './LeadsTable';
import AssignedFilterBtn from '../components/filterButtons/AssignedFilterBtn';
import LeadStageFilterBtn from '../components/filterButtons/LeadStageFilterBtn';
import TagsFilterBtn from '../components/filterButtons/TagsFilterBtn';
import SearchTextFilter from '../components/filterButtons/SearchTextFilter';
import { useLocation } from 'react-router-dom';

function LeadsTrackingPage() {
    const location = useLocation()
    const [leadsList, setLeadsList] = useState([])
    const [leadsStages, setLeadsStages] = useState([])

    
    useEffect(() => {
        let currentSearch = location.search
        // console.log('currentSearch', currentSearch)
        getData(apiLink + '/v1/internal/leads'+currentSearch)
        .then(async response => {
          if(response && response.ok ){
            const data = await response.json();
            if(data && data.data && data.data.length > 0 ){
              // console.log('LeadsList',data.data)
                setLeadsList(data.data)
            }
          }
        });

        getData(apiLink + '/v1/internal/leads/stages')
        .then(async response => {
          if(response && response.ok ){
            const data = await response.json();
            if(data && data.data && data.data.length > 0 ){ 
                // console.log('stages', data.data)
                setLeadsStages(data.data)
            }
          }
        });
  
      }, [location])

  return (
    <div className={`px-4 sm:px-1 sm:pl-6 sm:pr-3 py-1 min-h-[calc(100vh-10rem)] max-h-[calc(100vh-5rem)] overflow-scroll scrollbar-hide`}> 
        <div className='my-1 py-1 flex space-x-2'>
            <AssignedFilterBtn />
            <TagsFilterBtn />
            <LeadStageFilterBtn leadsStages={leadsStages} />
        </div>
        <div className='flex w-full my-1'>
          <SearchTextFilter />
        </div>
        <div className='w-full mt-2 p-2 overflow-x-scroll bg-slate-50 dark:bg-slate-800 rounded-xl  mb-4'>
            <LeadsTable data={leadsList} setLeadsList={setLeadsList} rowsPerPage={25} leadsStages={leadsStages} />
        </div>
    </div>
  )
}

export default LeadsTrackingPage