import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import React, { useState } from 'react'
import { LightTooltip } from '../styles/Tooltip';
import { apiLink } from '../ApiCalls/ApisVariables';
import { putData } from '../ApiCalls/DataApis';
import toast from 'react-hot-toast';

const priorities = [
    {
      "id": 1,
      "priority": "Critical",
      "color": "Red"
    },
    {
      "id": 2,
      "priority": "High",
      "color": "Orange"
    },
    {
      "id": 3,
      "priority": "Medium",
      "color": "Yellow"
    },
    {
      "id": 4,
      "priority": "Low",
      "color": "Green"
    }
  ]

function FeatureRequestTableRow({request, getFeaturesList}) {
    const [loadingPriority, setLoadingPriority] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [isEditingSubject, setIsEditingSubject] = useState(false);
    const [loadingSubject, setLoadingSubject] = useState(false);
    const [requestSubject, setRequestSubject] = useState(request.feature_request_subject || ''); // Handle initial value
    const [isEditingDescription, setIsEditingDescription] = useState(false);
    const [loadingDescription, setLoadingDescription] = useState(false);
    const [requestDescription, setRequestDescription] = useState(request.feature_request_description || ''); // Handle initial value

    const handleKeyPressSubject = (event) => {
        if (event.key === 'Enter' || event.key === 'Tab') {
            handleSubjectBlur(event);
        }
      };
  
      const handleSubjectBlur = (event) => {
          if(!event.target.value) {
              setRequestSubject(request.feature_request_subject)
              setIsEditingSubject(false);
              return;
          }
          if(!loadingSubject){
              setLoadingSubject(true)
              setIsEditingSubject(false);
              putData(apiLink + '/v1/internal/dashboard/feature_requests/' + request.feature_request_uuid , 
              { 
                    'feature_request_subject': event.target.value,
                    'feature_request_description': request.feature_request_description,
                    'feature_request_priority': request.feature_request_priority,
                    'feature_request_completed': request.feature_request_completed
                
              })
              .then(async response => {
                  const data = await response.json();
                  if(response.ok){
                      setLoadingSubject(false)
                      setRequestSubject(event.target.value)
                      getFeaturesList()
                  }
                  else{
                      try{
                          setRequestSubject(request.feature_request_subject)
                          setLoadingSubject(false)
                          toast.error(data.message)
                      }catch (e){
                          setRequestSubject(request.feature_request_subject)
                          toast.error(e.message)
                          setLoadingSubject(false)
                      }
                  }
              })
          }
  
      }
      
    const handleKeyPressDescription = (event) => {
        if (event.key === 'Enter' || event.key === 'Tab') {
            handleDescriptionBlur(event);
        }
      };
  
      const handleDescriptionBlur = (event) => {
          if(!event.target.value) {
            setRequestDescription(request.feature_request_description)
            setIsEditingDescription(false);
              return;
          }
          if(!loadingDescription){
            setLoadingDescription(true)
            setIsEditingDescription(false);
              putData(apiLink + '/v1/internal/dashboard/feature_requests/' + request.feature_request_uuid , 
              { 
                    'feature_request_subject': request.feature_request_description,
                    'feature_request_description': event.target.value,
                    'feature_request_priority': request.feature_request_priority,
                    'feature_request_completed': request.feature_request_completed
                
              })
              .then(async response => {
                  const data = await response.json();
                  if(response.ok){
                    setLoadingDescription(false)
                      setRequestDescription(event.target.value)
                      getFeaturesList()
                  }
                  else{
                      try{
                        setRequestDescription(request.feature_request_description)
                        setLoadingDescription(false)
                          toast.error(data.message)
                      }catch (e){
                        setRequestDescription(request.feature_request_description)
                          toast.error(e.message)
                          setLoadingDescription(false)
                      }
                  }
              })
          }
  
      }


    const formatDate = (date) => {
        if(date){
            const options = {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
              };
              
              const formatter = new Intl.DateTimeFormat('en-US', options); // Adjust locale if needed
              const formattedDate = formatter.format(new Date(date));
  
              return formattedDate
        }
    }


    const handleChangePriority = (priority_id) => {
        if(!loadingPriority){
            setLoadingPriority(true)
            putData(apiLink + '/v1/internal/dashboard/feature_requests/' + request.feature_request_uuid , 
            {
                'feature_request_subject': request.feature_request_subject,
                'feature_request_description': request.feature_request_description,
                'feature_request_priority': priority_id,
                'feature_request_completed': request.feature_request_completed
            })
            .then(async response => {
                const data = await response.json();
                if(response.ok){
                    setLoadingPriority(false)
                    getFeaturesList()
                }else{
                    setLoadingPriority(false)
                    toast.error(data.message)
                }
            })
        }
    }

    const handleChangeStatus = (isComplete) => {
        if(!loadingStatus){
            setLoadingStatus(true)
            putData(apiLink + '/v1/internal/dashboard/feature_requests/' + request.feature_request_uuid , 
            {
                'feature_request_subject': request.feature_request_subject,
                'feature_request_description': request.feature_request_description,
                'feature_request_priority': request.feature_request_priority,
                'feature_request_completed': isComplete
            })
            .then(async response => {
                const data = await response.json();
                if(response.ok){
                    setLoadingStatus(false)
                    getFeaturesList()
                }else{
                    setLoadingStatus(false)
                    toast.error(data.message)
                }
            })
        }

    }

    
  return (
    <>
        <tr className='border-b-[0.01em]  dark:border-zinc-500  hover:bg-slate-100 dark:hover:bg-slate-800'>
            <td className='relative pl-2 pr-2 py-1 w-[115px]'>
                <Listbox  value={request.feature_request_completed} onChange={handleChangeStatus}>
                    <ListboxButton className='justify-center text-center w-[115px]'>
                        {request.feature_request_completed ? (
                            <div
                            className={` w-fit text-center px-4 py-0.5 text-sm font-normal text-emerald-800 dark:text-emerald-900 bg-emerald-200/80 dark:bg-emerald-200  rounded-full`}>
                                Completed
                            </div>
                        ):(
                            <div
                            className={` w-fit px-4 py-0.5 text-sm font-normal text-blue-800 dark:text-blue-900 bg-blue-200/80 dark:bg-blue-200 rounded-full`}>
                                In-Progress 
                            </div>

                        )}
                    </ListboxButton>
                <ListboxOptions className='absolute top-10 inset-x-0  bg-white shadow-iotflows-lg dark:bg-slate-700 z-50 px-2 py-4 rounded-xl space-y-3'>
                        <ListboxOption
                            value={false}
                            className={`text-blue-800 dark:text-blue-900 bg-blue-200/80 dark:bg-blue-200 ui-active:bg-blue-200/50 ui-active:dark:bg-blue-200/80
                                        text-center rounded-full text-sm font-normal cursor-pointer`}
                        >
                                <span className={`mx-auto w-fit text-center px-4 py-1`}>
                                In-Progress 
                                </span>
                        </ListboxOption>
                        <ListboxOption
                            value={true}
                            className={`text-emerald-800 dark:text-emerald-900 bg-emerald-200/80 dark:bg-emerald-200 ui-active:bg-emerald-200/50 ui-active:dark:bg-emerald-200/80
                                        text-center rounded-full text-sm font-normal cursor-pointer`}
                        >
                                <span className={`mx-auto w-fit text-center px-4 py-1`}>
                                Completed
                                </span>
                        </ListboxOption>
                </ListboxOptions>
                </Listbox>
                {loadingStatus && 
                    <div >
                        <svg aria-hidden="true" className={`absolute top-1/4 right-1/2 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                        </svg>
                    </div>
                }
            </td>
            <td className='px-1 w-8'>
                <LightTooltip title={`${request.organization_name}`} arrow>
                    <img
                        src={request.organization_logo_url}
                        alt={request.organization_name}
                        className='h-6 w-6 rounded-md cursor-pointer'
                    />

                </LightTooltip>
            </td>
            {isEditingSubject ? (
            <td 
                className='text-sm font-light text-gray-600 dark:text-gray-200 h-6 px-2 py-1 w-fit min-w-[100px]'
            >
                <input
                type="text"
                name='request_subject'
                value={requestSubject}
                onChange={(e) => setRequestSubject(e.target.value)}
                onBlur={handleSubjectBlur} // Handle blur
                onKeyDown={handleKeyPressSubject}
                className={`block bg-transparent w-full border rounded-md py-0.5 px-3 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-sm placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300 dark:placeholder:text-gray-500 dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800`}
                autoFocus // Focus on input upon render
                />
            </td>
            ) : (
            <td 
                disabled={loadingSubject}
                onClick={() => setIsEditingSubject(true)}
                className='relative text-sm font-light text-gray-600 dark:text-gray-200 h-6 px-2 py-1 w-fit min-w-[100px] cursor-pointer'
                >
                    <div
                        className={`bg-transparent w-full border border-transparent rounded-md py-0.5 px-3  sm:text-sm  text-gray-600 dark:text-gray-300`}
                    >
                        {requestSubject}
                    </div>
                {loadingSubject && 
                <div >
                    <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                    </svg>
                </div>
                }
            </td>
            )}
            {isEditingDescription ? (
            <td 
                className='text-sm font-light text-gray-600 dark:text-gray-200 h-6 px-2 py-1 w-fit min-w-[100px]'
            >
                <input
                type="text"
                name='request_description'
                value={requestDescription}
                onChange={(e) => setRequestDescription(e.target.value)}
                onBlur={handleDescriptionBlur} // Handle blur
                onKeyDown={handleKeyPressDescription}
                className={`block bg-transparent w-full border rounded-md py-0.5 px-3 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-sm placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300 dark:placeholder:text-gray-500 dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800`}
                autoFocus // Focus on input upon render
                />
            </td>
            ) : (
            <td 
                disabled={loadingDescription}
                onClick={() => setIsEditingDescription(true)}
                className='relative text-sm font-light text-gray-600 dark:text-gray-200 h-6 px-2 py-1 w-fit min-w-[100px] cursor-pointer'
                >
                    <div
                        className={`bg-transparent w-full border border-transparent rounded-md py-0.5 px-3  sm:text-sm  text-gray-600 dark:text-gray-300`}
                    >
                        {requestDescription}
                    </div>
                {loadingDescription && 
                <div >
                    <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                    </svg>
                </div>
                }
            </td>
            )}
            <td className='w-8'>
                <img
                    src={request.user_image_url}
                    alt={request.user_first_name}
                    className='h-7 w-7 rounded-full'
                />
            </td>
            <td className='relative px-2 py-1 '>
                <Listbox  value={request.feature_request_priority} onChange={handleChangePriority}>
                    <ListboxButton className='w-full justify-center text-center'>
                        {request.feature_request_priority == 1 &&
                        <div
                        className={`mx-auto w-[85px] text-center px-4 py-0.5 text-sm font-normal text-red-800 dark:text-red-900 bg-red-200/80 dark:bg-red-200 rounded-full`}>
                            Critical
                        </div>
                        }
                        {request.feature_request_priority == 2 &&
                        <div
                        className={`mx-auto w-[85px] px-4 py-0.5 text-sm font-normal text-orange-800 dark:text-orange-900 bg-orange-200/80 dark:bg-orange-200 rounded-full`}>
                            High
                        </div>
                        }
                        {request.feature_request_priority == 3 &&
                        <div
                        className={`mx-auto w-[85px] px-4 py-0.5 text-sm font-normal text-yellow-800 dark:text-yellow-900 bg-yellow-200/80 dark:bg-yellow-200 rounded-full`}>
                            Medium
                        </div>
                        }
                        {request.feature_request_priority == 4 &&
                        <div
                        className={`mx-auto w-[85px] px-4 py-0.5 text-sm font-normal text-green-800 dark:text-green-900 bg-green-200/80 dark:bg-green-200 rounded-full`}>
                            Low
                        </div>
                        }
                    </ListboxButton>
                <ListboxOptions className='absolute top-10 inset-x-0  bg-white shadow-iotflows-lg dark:bg-slate-700 z-50 px-2 py-4 rounded-xl space-y-3'>
                    {priorities.map((priority) => (
                        <ListboxOption
                            key={priority.priority}
                            value={priority.id}
                            className={`${priority.id == 1 && 'text-red-800 dark:text-red-900 bg-red-200/80 dark:bg-red-200 ui-active:bg-red-200/50 ui-active:dark:bg-red-200/80'} 
                                        ${priority.id == 2 && 'text-orange-800 dark:text-orange-900 bg-orange-200/80 dark:bg-orange-200 ui-active:bg-orange-200/50 ui-active:dark:bg-orange-200/80'} 
                                        ${priority.id == 3 && 'text-yellow-800 dark:text-yellow-900 bg-yellow-200/80 dark:bg-yellow-200 ui-active:bg-yellow-200/50 ui-active:dark:bg-yellow-200/80'}
                                        ${priority.id == 4 && 'text-green-800 dark:text-green-900 bg-green-200/80 dark:bg-green-200 ui-active:bg-green-200/50 ui-active:dark:bg-green-200/80'}
                                        text-center rounded-full text-sm font-normal cursor-pointer`}
                        >
                                <span className={`mx-auto w-fit text-center px-4 py-1`}>
                                {priority.priority}
                                </span>
                        </ListboxOption>
                    ))}
                </ListboxOptions>
                </Listbox>
                {loadingPriority && 
                    <div >
                        <svg aria-hidden="true" className={`absolute top-1/4 right-1/2 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                        </svg>
                    </div>
                }
            </td>
            <td className='text-sm font-light text-gray-600 dark:text-gray-200 h-6 px-1 py-3 text-right w-[110px]'>{formatDate(request.feature_request_timestamp)}</td>
          </tr>
    </>
  )
}

export default FeatureRequestTableRow