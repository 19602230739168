import React, { useEffect, useState } from 'react'
import { getData, putData } from '../ApiCalls/DataApis'
import { apiLink } from '../ApiCalls/ApisVariables'
import toast from 'react-hot-toast';
import StarRating from '../components/StarRating';
import Switch from '@mui/material/Switch';
import { useNavigate } from 'react-router-dom';
import TagInput from '../components/forms/TagInput';
import Avatar from '@mui/material/Avatar';
import {MemberTooltip} from '../styles/Tooltip'
import { useRecoilValue } from 'recoil';
import { darkModeState } from '../atoms/darkModeState';
import ConfirmEmailResetModal from '../components/modals/ConfirmEmailResetModal.js';

function LeadsTableRow({el,leadsStages,leadsList,  setLeadsList}) {
    const navigate = useNavigate();
    const isDark = useRecoilValue(darkModeState)
    const [selectedDropdown, setSelectedDropdown] = useState('')
    const [loading, setLoading] = useState(false)
    const [leadComments, setLeadComments] = useState('');
    const [toggleAutoEmails, setToggleAutoEmails] = useState(true)
    const [loadingAutoEmails, setLoadingAutoEmails] = useState(false)
    const [showToolTip, setShowTooltip] = useState(false)
    const [trackingInfo, setTrackingInfo] = useState([])
    const [selectedTags, setSelectedTags] = useState([])


    useEffect(() => {
        if(el && Object.keys(el).length > 0){
            setToggleAutoEmails(el.lead_is_auto_email_enabled)
            if(el.lead_lead_stage_id){
                leadsStages.map((type, index) => {
                    if(type.lead_stage_id == el.lead_lead_stage_id){
                        setSelectedDropdown(index)
                    }
                })
            }
            if(el.lead_notes){
                setLeadComments(el.lead_notes)
            }
        }else {
            setSelectedDropdown('')
        }

        if(el.lead_tags){
            setSelectedTags(el.lead_tags)
          }


    }, [el])


    const handleOnChange = (e) => {
        try {
            if(!loading){
                setLoading(true)
                setSelectedDropdown(e.target.value)
              putData(apiLink + '/v1/internal/leads/'+ el.lead_id +'/stage' , 
              {'lead_stage_id': leadsStages[e.target.value].lead_stage_id})
                .then(async response => {
                  const data = await response.json();
                  if(response.ok){
                    toast.success('Stage updated')
                    setLoading(false)
                  }
                  else{
                    try{
                      toast.error(data.message)
                      setLoading(false)
                    }catch (e){
                        console.log('Something went wrong', e.message)
                        toast.error(e.message)
                      setLoading(false)
                    }
                  }
                })
            }
            
        } catch (error) {
        
        }
    }

    const handleDateFormat = (timestamp) => {
        if(timestamp){
            var currentDate = new Date(timestamp)
            var currentHours = currentDate.getHours();
            var currentMinutes = currentDate.getMinutes();
            var currentAmPm = currentHours >= 12 ? 'pm' : 'am';
            currentHours = currentHours % 12;
            currentHours = currentHours ? currentHours : 12; // the hour '0' should be '12'
            currentMinutes = currentMinutes < 10 ? '0'+currentMinutes : currentMinutes;
            var currentStrTime = currentHours + ':' + currentMinutes + '' + currentAmPm;
            var shortcurrentDate = currentDate.getMonth()+1 + "/" + currentDate.getDate() + "/" + currentDate.getFullYear().toString().substr(-2)
            // var currentTime = currentDate.getMonth()+1 + "/" + currentDate.getDate() + "/" + currentDate.getFullYear().toString().substr(-2) + " " + currentStrTime;
            // var startTime = startDate.getMonth()+1 + "/" + startDate.getDate() + "/" + startDate.getFullYear().toString().substr(-2) + " " + startStrTime;
            return currentStrTime + "  " + shortcurrentDate  ;
        }
    }

    const handleChangeLeadNotes = (e) => {
        try {
            setLeadComments(e.target.value)
              putData(apiLink + '/v1/internal/leads/'+ el.lead_id +'/notes' , 
              {'lead_notes': e.target.value})
                .then(async response => {
                  const data = await response.json();
                  if(response.ok){
                    // console.log('Leads notes', data)
                  }
                  else{
                    try{
                      console.log(data.message)
                    }catch (e){
                        console.log('Something went wrong', e.message)
                    }
                  }
                })
            
        } catch (error) {
        
        }
    }

    const handleChangeAutoEmails = (e) => {
        let getUptimeNotification = e.target.checked? 'ON':'OFF'
        setToggleAutoEmails(e.target.checked)
        if(!loadingAutoEmails){
            try {
                setLoadingAutoEmails(true)
                putData(apiLink + '/v1/internal/leads/' + el.lead_id + '/auto', 
                {'is_auto_email_enabled': e.target.checked})
                .then(async response => {
                    const data = await response.json();
                    if(response.ok){
                        setToggleAutoEmails(e.target.checked)
                        setLoadingAutoEmails(false)
                        toast.success(`Automated emails are ${getUptimeNotification}`)
                    }
                    else{
                        try{
                            const data = await response.json();
                            setLoadingAutoEmails(false)
                            toast.error(data.message)
                        }catch (e){
                            toast.error('Something went wrong')
                            setLoadingAutoEmails(false)
                        }
                    }
                })
            }catch {
                setLoadingAutoEmails(false)
            }
        }
      }

      // **** Functions for Input tag **** 
        const handleTagKeydown = (e) => {
            if(e.key === 'Enter' || e.key === 'Tab') {
                const value = e.target.value
                
                if(!value.trim()) return

                if(selectedTags.includes(value)) return
                
                setSelectedTags([...selectedTags, value])
                
                let tempArray = [...selectedTags, value]
                e.target.value= ''
                
                putData(apiLink + '/v1/internal/leads/'+ el.lead_id +'/tags' , 
                {'tags': tempArray})
                .then(async response => {
                const data = await response.json();
                if(response.ok){
                    // toast.success('Tags updated')
                    // setLoading(false)
                }
                else{
                    try{
                    toast.error(data.message)
                    // setLoading(false)
                    }catch (e){
                        console.log('Something went wrong', e.message)
                        toast.error(e.message)
                    // setLoading(false)
                    }
                }
                })

            } 
            
        };
        

        const handleAddTagFromList = (tag) => {
            if(selectedTags.includes(tag)) return
            setSelectedTags([...selectedTags, tag])

            let tempArray = [...selectedTags, tag]
            
            putData(apiLink + '/v1/internal/leads/'+ el.lead_id +'/tags' , 
            {'tags': tempArray})
            .then(async response => {
            const data = await response.json();
            if(response.ok){
                // toast.success('Tags updated')
                // setLoading(false)
            }
            else{
                try{
                toast.error(data.message)
                // setLoading(false)
                }catch (e){
                    console.log('Something went wrong', e.message)
                    toast.error(e.message)
                // setLoading(false)
                }
            }
            })
        }

        const addTag = (tagValue) => {
            if(!tagValue.trim()) return
            // setSelectedTags([...selectedTags, tagValue])
        }

        const removeTag = (index) => {
            setSelectedTags(selectedTags.filter((el,i) => i !== index))

            let tempArray = selectedTags.filter((el,i) => i !== index)
            
            putData(apiLink + '/v1/internal/leads/'+ el.lead_id +'/tags' , 
            {'tags': tempArray})
            .then(async response => {
            const data = await response.json();
            if(response.ok){
                // toast.success('Tags updated')
                // setLoading(false)
            }
            else{
                try{
                toast.error(data.message)
                // setLoading(false)
                }catch (e){
                    console.log('Something went wrong', e.message)
                    toast.error(e.message)
                // setLoading(false)
                }
            }
            })
        }

        // **** Functions for Input Tags **** 

        
  return (
    <>
    <tr className='border-b-[0.01em] dark:border-slate-700'>
        <td className='px-1 py-3'>
            <div className='flex flex-col text-sm font-normal text-gray-600 dark:text-gray-300'>
                <span >
                    {/* {handleDateFormat(el.lead_created_at)} */}
                    {el.date}
                </span>
                <span className='text-gray-500 dark:text-gray-400 text-xs'>
                    {el.lead_source}
                </span>

            </div>
        </td>
        <td className=' p-2'>
            <div className=''>
                <Avatar 
                    alt={el.user_first_name}
                    src={el.user_image_url} 
                    sx={{ width: 36, height: 36, marginLeft:'auto', marginRight:'auto' }}
                />
            </div>
        </td>
        <td className='text-sm font-normal text-gray-600 dark:text-gray-300 px-1 py-3 '>
            <div className='flex items-center'>
                {el.email_seen_count > 0 ? (
                    <MemberTooltip  
                        placement="right"
                        title={
                            <div className={`rounded-xl px-3 py-1.5 min-w-[250px] ${isDark ? ' bg-slate-500 ':' bg-slate-300 '}`}>
                            {el && el.email_tracking_address && Object.keys(el.email_tracking_address).length > 0 && el.email_tracking_address.map((emailInfo,index) => (
                                <div key={`email-tracking-${el.lead_id}`} className={`flex flex-col justify-between text-xs ${isDark ? ' text-white ':' text-gray-700'} `}>
                                    <div className={`flex justify-between ${isDark ? ' text-white ':' text-gray-700'}  text-sm`}>
                                        <div className='py-0.5'>{`Seen: ${emailInfo.email_seen_count}`}</div>
                                        <div className='py-0.5'>{handleDateFormat(emailInfo.email_sent_timestamp)}</div>
                                    </div>
                                </div>
                            ))}
                            </div>
                        }
                    >
                        <div className='flex pr-4'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`w-4 h-4 text-blue-600 dark:text-blue-300`}>
                                <path fillRule="evenodd" d="M14.615 1.595a.75.75 0 01.359.852L12.982 9.75h7.268a.75.75 0 01.548 1.262l-10.5 11.25a.75.75 0 01-1.272-.71l1.992-7.302H3.75a.75.75 0 01-.548-1.262l10.5-11.25a.75.75 0 01.913-.143z" clipRule="evenodd" />
                            </svg>
                            <span className='text-xs pl-0.5 font-normal text-blue-600 dark:text-blue-300'>{el.email_seen_count}</span>
                        </div>
                    </MemberTooltip> 

                ):(
                    <div className='flex pr-4'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`w-4 h-4 text-gray-400 dark:text-gray-500`}>
                            <path fillRule="evenodd" d="M14.615 1.595a.75.75 0 01.359.852L12.982 9.75h7.268a.75.75 0 01.548 1.262l-10.5 11.25a.75.75 0 01-1.272-.71l1.992-7.302H3.75a.75.75 0 01-.548-1.262l10.5-11.25a.75.75 0 01.913-.143z" clipRule="evenodd" />
                        </svg>
                        <span className='text-xs pl-0.5 font-normal text-gray-400 dark:text-gray-500'>{el.email_seen_count}</span>
                    </div>
                )}
                <div className='truncate cursor-pointer	flex flex-col' onClick={() => navigate(`/emails?q=${el.lead_email}`)}>
                    <span className='text-gray-600 dark:text-gray-300'>{`${el.lead_first_name} ${el.lead_last_name}`}</span>
                    <span className='text-gray-600 dark:text-gray-300'>{el.lead_email}</span>
                    <span className='text-xs text-gray-600 dark:text-gray-400 italic pl-0.5'>{`${el.lead_job_title}`}</span>
                </div>
            </div>
        </td>
        <td className='text-sm font-normal text-gray-600 dark:text-gray-300 px-1 py-3 '>
            <div className='flex flex-col truncate '>
                <div>{el.lead_website}</div>
                <div className='text-xs text-gray-600 dark:text-gray-400 italic pl-0.5'>{el.lead_timeline}</div>
                <span className='text-xs text-gray-600 dark:text-gray-400 italic pl-0.5'>{`Machines: ${el.lead_machines_wanting_to_optimize}`}</span>
            </div>
        </td>
        {/* <td className='text-sm font-normal text-gray-600 dark:text-gray-300 px-1 py-3 min-w-[100px]'>
            <div className='w-full flex items-center justify-center'>
                <StarRating el={el} />

            </div>
        </td> */}
        <td className='text-sm font-normal text-gray-600 dark:text-gray-300 pl-1 pr-3 py-3 min-w-[150px]'>
            <div className="flex justify-center">
                <div className={`relative w-full outline-none`}>
                    <select 
                        value={selectedDropdown}
                        onChange={handleOnChange}  
                        className={`text-gray-700 border-slate-50 focus:text-gray-700 focus:bg-white focus:border-slate-50 dark:text-gray-200 dark:border-gray-600 dark:focus:text-gray-300 dark:focus:bg-slate-700 dark:focus:border-slate-400
                        bg-transparent form-select form-select-sm shadow-iotflows-lg appearance-none block w-full px-2 py-1 text-sm font-normal bg-clip-padding bg-no-repeat border-1 rounded transition ease-in-out m-0 focus:outline-none focus:shadow-blue-300`} 
                        aria-label="Machine Type selection"
                    >
                        {/* <option value={''}  >{""}</option> */}
                        {leadsStages.map((type, index) => (
                            <option key={type.lead_stage_id} value={index}>{type.lead_stage_name}</option>
                        ))}
                    </select>
                    {loading && 
                        <div >
                            <svg aria-hidden="true" className={`absolute top-[5px] left-0 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                            </svg>
                        </div>
                    }
                </div>
            </div> 
        </td>
        <td className='text-sm font-normal text-gray-600 dark:text-gray-300 px-1 py-3 text-center'>
            <div className={`flex`}>
                <div className='relative mx-auto my-auto'>
                <Switch
                    color="primary"
                    checked={toggleAutoEmails}
                    onChange={handleChangeAutoEmails}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
                {loadingAutoEmails && 
                    <div >
                        <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                        </svg>
                    </div>
                }
                </div>
            </div>
        </td>
        <td className='text-sm font-normal text-gray-600 dark:text-gray-300 px-1 py-3 text-center'>
            <ConfirmEmailResetModal el={el} leadsList={leadsList} setLeadsList={setLeadsList}  /> 
        </td>
        <td className='text-sm font-normal text-gray-600 dark:text-gray-300 px-1 py-3 text-center'>
            <TagInput tags={selectedTags} onKeyDown={handleTagKeydown} addTag={addTag} removeTag={removeTag} handleAddTagFromList={handleAddTagFromList}/>
        </td>
        <td className='text-sm font-normal pr-1 min-w-[150px]' >
            <textarea
                className={`block border-0 grow w-full ml-1 pl-2 sm:text-sm rounded-md text-ellipsis py-1 dark:placeholder:text-gray-500 dark:text-gray-300 dark:focus:border-transparent dark:focus:ring-transparent dark:bg-slate-700 placeholder:text-gray-400 text-gray-600 focus:border-transparent focus:ring-transparent bg-slate-100`}
                placeholder="Comments" 
                rows={1} 
                onChange={handleChangeLeadNotes} 
                value={leadComments}
            />
        </td>
    </tr>
    </>
  )
}

export default LeadsTableRow