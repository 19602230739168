// import HighchartsStock from 'highcharts/highstock';
// import highchartsMore from "highcharts/highcharts-more.js";
import HighchartsReact from "highcharts-react-official";
import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { darkModeState } from '../atoms/darkModeState';
import Highcharts from 'highcharts'

function SenseAiDeploymentChart({data}) {
    const [chartOptions, setChartOptions] = useState({
      chart: {
        backgroundColor: 'transparent',
        // height: '300px'
      },
      title: {
        text: ''
        },
      yAxis: {
          title: {
              text: ''
          }
      },
      credits : { enabled : false},
    })
    const isDark = useRecoilValue(darkModeState);


    useEffect(() => {
        if(data){
            let categories = []
            let registered = []
            let onboarded = []
            let online = []
            data.map(month => {
              categories.push(month.to_char)
              registered.push(Number(month.senseai_count))
              onboarded.push(Number(month.senseai_assigned_count))
              online.push(Number(month.senseai_online_count))
              
            })
            
            setChartOptions({
              chart: {
                  backgroundColor: 'transparent',
                  // height: '300px'
              },
              title: {
                text: ''
                },
              yAxis: {
                  title: {
                      text: ''
                  }
              },
              legend: {
                  layout: 'horizontal',
                  align: 'center',
                  verticalAlign: 'bottom',
                  itemStyle: {
                    fontSize:'14px',
                    color: isDark ? '#d1d5db':'#4b5563'
                 },

              },
              tooltip: {
                shared: true,
                // headerFormat: '<b>Hunting season starting autumn {point.x}</b><br>'
              },
              plotOptions: {
                  series: {
                      label: {
                          connectorAllowed: false
                      },
                      lineWidth: 2
                  },
                  line: {
                    dataLabels: {
                        enabled: true,
                        style: {
                          fontWeight: 500,
                          color: isDark ? '#d1d5db':'#4b5563',
                          fontSize:14
                      }
                    },
                }
              },
              xAxis: {
                  categories: categories,
                  labels: {
                      style: {
                          color: isDark ? '#d1d5db':'#4b5563'
                      }
                  }
              },
              yAxis:[{ 
                labels: {
                  style: {
                    color: isDark ? '#d1d5db':'#4b5563'
                  }
                },
                plotLines: [{
                    value: 0,
                    width: 2,
                    color: 'silver'
                }],
                title: {
                    text: '',
                  },
                lineWidth: 0,
                minorGridLineWidth: 0,
                lineColor: 'transparent',
                // labels: {
                //     enabled: false
                // },
                // min:0,
                minorTickLength: 0,
                tickLength: 0,
                gridLineWidth: 0,
                opposite: false
        
              }],
              credits : { enabled : false},
              series: [{
                  name: 'SenseAi Registered',
                  color: '#166534',
                  data: registered
              }, {
                  name: 'SenseAi Onboarded',
                  color: '#16a34a',
                  data: onboarded
              }
              // , {
              //     name: 'SenseAi Online',
              //     color: '#22c55e',
              //     data: online
              // }
            ],

              responsive: {
                  rules: [{
                      condition: {
                          maxWidth: 500
                      },
                      chartOptions: {
                          legend: {
                              layout: 'horizontal',
                              align: 'center',
                              verticalAlign: 'bottom'
                          }
                      }
                  }]
              }
            })

        }

    }, [data, isDark])
  return (
    <div className='mt-2 p-4 bg-slate-50 dark:bg-slate-700 rounded-3xl flex flex-col text-center w-full'>
      <span className={`${isDark? 'text-gray-300':'text-gray-600'} text-xl`}>SenseAi Deployment</span>
        <HighchartsReact
            // highcharts={HighchartsStock}
            highcharts={Highcharts}
            // constructorType={'stockChart'}
            options={chartOptions}
        />
    </div>
  )
}

export default SenseAiDeploymentChart