
import { useState } from "react";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {darkModeState} from '../atoms/darkModeState'



function Nav() {
    const isDark = useRecoilValue(darkModeState);
    let location = useLocation();
    const [selectedTab, setSelectedTab] = useState('');
    const [isMobile, setIsMobile] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()

    const navigationLinks = [
        {
            title: 'Overview',
            // url:`/assets`
            url:`/`
        },
        {
            title: 'Customers',
            url:`/customer`
        },
        {
            title: 'Leads',
            url:`/leads`
        },
        {
            title: 'Emails',
            url:`/emails`
        },
    ]

    useEffect(() => {
        let currentIsMobile = searchParams.get('platform')
        if(currentIsMobile === 'mobile'){
          setIsMobile(true)
        }else{
          setIsMobile(false)
        }
    },[])

    useEffect(()=>{
        if(location && location.pathname){
            let currentPath = location.pathname.split('/')[1]
            if(currentPath && currentPath.split('?') && currentPath.split('?')[0]){
                currentPath = currentPath.split('?')[0]
            }
            for (let i = 0; i < Object.keys(navigationLinks).length; i++) {
                let navLink = navigationLinks[i].url.split('/')[1]
                if(navLink && navLink.split('?') && navLink.split('?')[0]){
                    navLink = navLink.split('?')[0]
                }
                if(currentPath === navLink){
                    setSelectedTab(navigationLinks[i])
                    break;
                    }else {
                        setSelectedTab(
                            {
                                title: currentPath,
                                url:`/${currentPath}`
                            },
                        )
                    }
                }
        }
    },[location])

    

    let nonActiveStyle = {
        backgroundColor:'transparent',
        borderRadius:'50px',
        color: isDark ? '#F3F4F6':'#4b5563',
    }

    let activeStyle = {
        backgroundColor:'transparent',
        borderRadius:'50px',
        color: isDark ? '#F3F4F6':'#4b5563',
        // backgroundColor:'#2563eb',
        // color:'#fff',
        // borderRadius:'50px',
        // color:'#ffff',
      };
      
    return (
        <nav className={`${isMobile && 'hidden'} relative flex mx-2 py-1.5 lg:ml-5 lg:mr-3 border-b-[1px] border-slate-100 dark:border-slate-800 bg-slate-200 dark:bg-slate-900`}>
            <div className='flex  overflow-x-scroll scrollbar-hide space-x-2'>
                {navigationLinks.map(link => (
                    
                    <NavLink
                        key={link.url}
                        to={link.url}
                        className='relative font-semibold outline-none text-sm cursor-pointer px-2 py-0.5 rounded-full '
                        onClick={()=> setSelectedTab(link)}
                        style={({ isActive }) =>
                        isActive ? activeStyle : nonActiveStyle
                        }
                    >
                        <h1 key={link.url} className='bg-transparent hover:text-gray-500 focus:rounded-full '>
                        {link.title}
                    </h1>
                    {link.title == selectedTab.title ? (
                        <motion.div transition={{ duration: 0.20 }} className="mainUnderline" layoutId="mainUnderline">{link.title}</motion.div>
                    ) : null }
                    
                  </NavLink>
                  
                //     <NavLink
                //     key={link.url}
                //     to={link.url}
                //     style={({ isActive }) =>
                //       isActive ? activeStyle : nonActiveStyle
                //     }
                //   >
                //     <h1 key={link.url} className='font-semibold outline-none text-base cursor-pointer px-4 py-0.5 bg-transparent rounded-full hover:bg-blue-600 hover:text-white focus:rounded-full last:pr-4 '>
                //         {link.title}
                //     </h1>
                //   </NavLink>
                    
                ))}
            </div>
            {/* <div className='absolute top-0 right-0 bg-gradient-to-l from-white dark:from-slate-900 h-9 w-1/12 sm:h-0 sm:w-0' /> */}
            
        </nav>
    )
}

export default Nav
