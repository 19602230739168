
import { Dialog, Transition, TransitionChild, DialogPanel, DialogTitle } from '@headlessui/react'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react'
import { PlusSmIcon } from "@heroicons/react/solid"
import { motion } from "framer-motion";
import { useRecoilValue } from 'recoil';
import { toast } from 'react-hot-toast';
import GeneralFormInput from '../forms/GeneralFormInput';
import { darkModeState } from '../../atoms/darkModeState';
import { getData, postData } from '../../ApiCalls/DataApis';
import { apiLink } from '../../ApiCalls/ApisVariables';


const priorities = [
    {
      "id": 1,
      "priority": "Critical",
      "color": "Red"
    },
    {
      "id": 2,
      "priority": "High",
      "color": "Orange"
    },
    {
      "id": 3,
      "priority": "Medium",
      "color": "Yellow"
    },
    {
      "id": 4,
      "priority": "Low",
      "color": "Green"
    }
  ]


function AddFeatureRequestModal({remoteOpenPartModal, getFeaturesList}) {
  const isDark = useRecoilValue(darkModeState);
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [priorityId, setPriorityId] = useState(3)
  const [form, setForm] = useState({
    subject: '',
    description: '',
  });
  const [filteredOrgList, setFilteredOrgList] = useState([])
  const [orgList, setOrgList] = useState([])
  const [selectedOrganization, setSelectedOrganization] = useState([])
  const [orgSearchText, setOrgSearchText] = useState('')

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
    setSelectedOrganization([])
    setPriorityId(3)
    setForm({
      subject: '',
      description: '',
    })
    setOrgSearchText('')
  }

    useEffect(() => {
        getData(apiLink + '/v1/organizations')
        .then(async response => {
        if(response.ok ){
            const data = await response.json();
            
            if(data && data.data){
                setOrgList(data.data)
            // setIsLoadingOrgs(false)
            }
        }else{
            // setIsLoadingOrgs(false)
        }
        
        });
    },[])

    useEffect(() => {
        if(orgList && Object.keys(orgList).length > 0){
            setFilteredOrgList(orgList)
        }
    }, [orgList])



  // this useEffect is used when opening up the modal remotely through another modal
  useEffect(() => {
    if(remoteOpenPartModal){
      setIsOpen(true)
    }
  },[remoteOpenPartModal])

  const handleFilterOrgList = (e) => {
    setOrgSearchText(e.target.value)
    if (!Array.isArray(orgList)) {
        throw new Error("Input must be a JSON array");
      }
    
    // Convert both name and array elements to lowercase for case-insensitive matching
    const lowercaseName = e.target.value.toLowerCase();
    const lowercasedData = orgList.map(item => ({ ...item, organization_name: item.organization_name.toLowerCase() }));

    let filteredArray = []
    lowercasedData.filter((item,index) => {
        const itemName = item.organization_name;
        if (itemName.includes(lowercaseName)) {
            filteredArray.push(orgList[index])
        }
    });
    setFilteredOrgList(filteredArray)

    }

    const handleSelectedOrganizationChange = (e) => {
        if(e){
            setSelectedOrganization(e)
        }
    }



  const handleGeneralFormChange = (event) => {
    // Get the name of the field that caused this change event
    // Get the new value of this field
    const { name, value } = event.target;

    // Assign new value to the appropriate form field
    const updatedForm = {
      ...form,
      [name]: value
    };
    // Update state
    setForm(updatedForm);
  }

  const submitNewFeatureRequest = (e) => {
    e.preventDefault()
    try {
      if(!loading){
        setLoading(true)
        console.log('priorityId', priorityId)
        postData(apiLink + `/v1/internal/dashboard/organizations/${selectedOrganization.organization_uuid}/feature_requests`, 
        {
            'feature_request_subject': form.subject,
            'feature_request_description': form.description,
            'feature_request_priority': priorityId,
        })
        .then(async response => {
            const data = await response.json();
            if(response.ok){
                setLoading(false)
                closeModal()
                getFeaturesList()
            }else{
                setLoading(false)
                toast.error(data.message)
            }
        })

      }
      
    } catch (error) {
      console.log(error.message)
    }

  }

  const handleChangePriority = (id) => {
    setPriorityId(id)
  }



  return (
    <>
      <div className="flex items-center justify-end">
          <motion.button  whileHover={{ scale: 1.03 }} whileTap={{ scale: 0.97 }}  className='btnMain' onClick={openModal}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="text-white w-5 h-5">
                <path d="M10.75 4.75a.75.75 0 0 0-1.5 0v4.5h-4.5a.75.75 0 0 0 0 1.5h4.5v4.5a.75.75 0 0 0 1.5 0v-4.5h4.5a.75.75 0 0 0 0-1.5h-4.5v-4.5Z" />
                </svg>

              <span className='btnMainText'>Add Feature Request</span>
          </motion.button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className={`w-full max-w-lg transform overflow-hidden rounded-2xl text-left align-middle shadow-xl transition-all ${isDark?'bg-slate-800':'bg-white'}`}>
                  <DialogTitle
                    as="h3"
                    className={`mb-1 p-4 text-lg font-medium leading-6 border-b-[1px] ${isDark?'text-gray-100 border-gray-700':'text-gray-700 border-gray-200'}`}
                  >
                    Feature Request
                  </DialogTitle> 
                    <div className='px-4'>
                        <div className='relative mt-4 flex items-center'>
                            <div className={`text-sm font-medium ${isDark?'text-gray-300':'text-gray-600'} mr-4`}>
                                Requested by Organization
                            </div>
                            <Listbox as="div" value={selectedOrganization} onChange={handleSelectedOrganizationChange} className='relative h-7 z-[999]'>
                                <ListboxButton>
                                <div className='flex max-w-[200px] space-x-1 oveflow-hidden items-center cursor-pointer '>
                                    {selectedOrganization && selectedOrganization.organization_logo_url?(
                                            <img
                                                src={selectedOrganization.organization_logo_url}
                                                alt={selectedOrganization.organization_name}
                                                className='h-7 w-7 rounded-md cursor-pointer'
                                            />
                                        ) : (
                                            <div className={`${isDark ? 'text-gray-300' : 'text-gray-400'}  h-7 w-7 rounded-md cursor-pointer items-center  group-hover:text-gray-300`}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z" />
                                                </svg>
                                            </div>  
                                        )}
                                    <div className={`${isDark ? 'text-gray-100' : 'text-gray-600'} text-sm hidden md:inline-flex truncate max-w-[125px] xl:max-w-[145px]`}>
                                        <span>{selectedOrganization && selectedOrganization.organization_name ?  selectedOrganization.organization_name : 'Select Org'}</span>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`h-5 w-5 ${isDark ? 'text-gray-100' : 'text-gray-600'} hidden sm:inline-flex`}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                    </svg>

                                </div>
                                </ListboxButton>
                                <Transition
                                    enter="transition duration-100 ease-out"
                                    enterFrom="transform scale-95 opacity-0"
                                    enterTo="transform scale-100 opacity-100"
                                    leave="transition duration-75 ease-out"
                                    leaveFrom="transform scale-100 opacity-100"
                                    leaveTo="transform scale-95 opacity-0"
                                >
                                    <ListboxOptions className={`absolute left-32 max-h-[250px] w-[275px]  scrollbar-hide overflow-y-scroll -translate-x-1/2 ${isDark ? 'bg-slate-700' : 'bg-white'} shadow-iotflows-lg rounded-lg`}>
                                        <div >
                                            <input className={`w-full ${isDark? 'hover:bg-gray-700 focus:bg-gray-700 bg-gray-600 focus:ring-gray-500 text-gray-300 placeholder:text-gray-400' : 'bg-gray-50 text-gray-600 border-gray-300 focus:ring-gray-300 focus:border-gray-300 placeholder:text-gray-500'}  
                                                    text-xs rounded-lg text-ellipsis`}
                                                value={orgSearchText} 
                                                onChange={handleFilterOrgList} 
                                                type="text" 
                                                placeholder="Search..."
                                                autoFocus
                                                
                                            />
                                        </div>
                                    {filteredOrgList.map((org) => (
                                        <ListboxOption key={org.organization_uuid} value={org}>
                                        <div className='group flex w-full items-center cursor-pointer rounded-lg py-1 pl-1 pr-3 hover:bg-blue-600 text-gray-600 hover:text-white'>
                                            {org.organization_logo_url?(
                                                <img
                                                    src={org.organization_logo_url}
                                                    alt={org.organization_name}
                                                    className='h-7 w-7 rounded-md cursor-pointer'
                                                />
                                            ) : (
                                                <div className={`${isDark ? 'text-gray-300 group-hover:text-gray-300' : 'text-gray-400'} h-7 w-7 rounded-md cursor-pointer items-center group-hover:text-gray-300`}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 m-auto">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z" />
                                                    </svg>
                                                </div> 
                                            )}
                                            <div className={`pl-1 ${isDark ? 'text-gray-100' : 'text-gray-600  group-hover:text-white'} text-sm truncate`}>{org.organization_name}</div>
                                        </div>
                                        </ListboxOption>
                                    ))}
                                    </ListboxOptions> 
                                </Transition>
                            </Listbox>
                        </div>
                        <div className='relative mt-4 flex items-center'>
                            <div className={`text-sm font-medium ${isDark?'text-gray-300':'text-gray-600'} mr-4`}>
                                Priority
                            </div>
                            <Listbox  value={priorityId} onChange={handleChangePriority}>
                                <ListboxButton className=' justify-center text-center'>
                                    {priorityId == 1 &&
                                    <div
                                    className={`mx-auto w-fit text-center px-4 py-0.5 text-sm font-normal ${isDark?'text-red-900 bg-red-200':'text-red-800 bg-red-200/80'} rounded-full`}>
                                        Critical
                                    </div>
                                    }
                                    {priorityId == 2 &&
                                    <div
                                    className={`mx-auto w-fit px-4 py-0.5 text-sm font-normal ${isDark?'text-orange-900 bg-orange-200':'text-orange-800 bg-orange-200/80'} rounded-full`}>
                                        High
                                    </div>
                                    }
                                    {priorityId == 3 &&
                                    <div
                                    className={`mx-auto w-fit px-4 py-0.5 text-sm font-normal ${isDark?'text-yellow-900 bg-yellow-200':'text-yellow-800 bg-yellow-200/80'}  rounded-full`}>
                                        Medium
                                    </div>
                                    }
                                    {priorityId == 4 &&
                                    <div
                                    className={`mx-auto w-fit px-4 py-0.5 text-sm font-normal ${isDark?'text-green-900 bg-green-200':'text-green-800 bg-green-200/80'} rounded-full`}>
                                        Low
                                    </div>
                                    }
                                </ListboxButton>
                            <ListboxOptions className={`absolute top-10 inset-x-0 ${isDark?'bg-slate-700':'bg-white'}   shadow-iotflows-lg  z-50 px-2 py-4 rounded-xl space-y-3 w-[300px]`}>
                                {priorities.map((priority) => (
                                    <ListboxOption
                                        key={priority.priority}
                                        value={priority.id}
                                        className={`${priority.id == 1 && `${isDark?'text-red-900    bg-red-200    ui-active:bg-red-200/80   ':'text-red-800       bg-red-200/80 ui-active:bg-red-200/50'}`} 
                                                    ${priority.id == 2 && `${isDark?'text-orange-900 bg-orange-200 ui-active:bg-orange-200/80':'text-orange-800 bg-orange-200/80 ui-active:bg-orange-200/50'}`} 
                                                    ${priority.id == 3 && `${isDark?'text-yellow-900 bg-yellow-200 ui-active:bg-yellow-200/80':'text-yellow-800 bg-yellow-200/80 ui-active:bg-yellow-200/50'}`}
                                                    ${priority.id == 4 && `${isDark?'text-green-900  bg-green-200  ui-active:bg-green-200/80  ':'text-green-800 bg-green-200/80  ui-active:bg-green-200/50 '}`}
                                                    text-center rounded-full text-sm font-normal cursor-pointer`}
                                    >
                                            <span className={`mx-auto w-fit text-center px-4 py-1`}>
                                            {priority.priority}
                                            </span>
                                    </ListboxOption>
                                ))}
                            </ListboxOptions>
                            </Listbox>
                        </div>
                        <div className="pt-4">
                        <GeneralFormInput label='Subject' type='text' placeholder='' name='subject' onChange={handleGeneralFormChange} />
                        </div>
                        <div className="pt-4">
                        <GeneralFormInput label='Description' type='text' placeholder='' name='description' onChange={handleGeneralFormChange} />
                        </div>
                    </div>
                  <form >
                    <div className={`flex p-4 px-5 items-center mt-4 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                      <button
                        type="button"
                        className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                      <button
                        className={`relative ml-auto inline-flex rounded-md border border-transparent ${isDark?' text-blue-100 bg-blue-900 hover:bg-blue-800 disabled:bg-blue-700':'text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                        onClick={submitNewFeatureRequest}
                        disabled={loading}
                      >
                        Add Feature
                        {loading && 
                            <div >
                                <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                </svg>
                            </div>
                          }
                      </button>
                    </div>
                  </form>
                  
                </DialogPanel>
              </TransitionChild> 
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default AddFeatureRequestModal