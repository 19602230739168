import React, { useState } from "react";
import useTable from "../components/hooks/useTable";
import TableFooter from "../components/TableFooter";
import EmailTableRow from "./EmailTableRow";
import { useSearchParams } from "react-router-dom";

function EmailTable({ data, rowsPerPage, searchArray, setSearchArray }) {
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(data, page, rowsPerPage);
  const [searchParams, setSearchParams] = useSearchParams()

  const removeSearchUser = (index) => {
    let array = searchArray
    if (index > -1) { // only splice array when item is found
      array.splice(index, 1); // 2nd parameter means remove one item only
    }
    // console.log('array', array)
    setSearchArray(array)

    setSearchParams({q: array})
  }

  
  return (
    <>
      <div className='flex justify-between'>
        <div className='flex space-x-2'>
        {searchArray.map((user, index) => (
          <div key={index} className='flex bg-slate-200 dark:bg-slate-600 rounded-full px-3.5 py-0.5 items-center'>
            <span className='text-gray-600 dark:text-gray-300'>{user}</span>
            <span className='pl-1 items-center cursor-pointer' onClick={() => removeSearchUser(index)}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-gray-600 dark:text-gray-300 hover:text-gray-800 hover:dark:text-gray-100 ">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </span>
          </div>
        ))}
        </div>
        <TableFooter range={range} slice={slice} setPage={setPage} page={page} rowsPerPage={rowsPerPage} data={data} />
      </div>
      <table className="table-fixed w-full ">
            <colgroup >
                <col className='w-[40px]'/>
                <col className='w-[200px]'/>
                <col className='w-full'/>
                <col className='w-[150px]'/>
                
            </colgroup>
          <tbody className=''>
          {slice.map((el,index) => (
            <EmailTableRow el={el} index={index} key={index} />
          ))}
        </tbody>
      </table>
      
    </>
  );
};

export default EmailTable;