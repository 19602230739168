import React from 'react'

function CustomerInfoCard({selectedCustomerData}) {
  return (
    <div className='sm:max-w-[400px] px-4 py-4 overflow-scroll scrollbar-hide bg-slate-50 dark:bg-slate-700 rounded-3xl h-[550px]'>
        <div className='mb-2 flex justify-center'>
            <img
                src={selectedCustomerData.organization_logo_url}
                alt={selectedCustomerData.organization_name}
                className={`h-20 w-20 rounded-md`}
            />
        </div>
        <div className='grid grid-cols-1'>
            <div>
                <p className='text-gray-500 dark:text-gray-400 my-auto text-sm font-medium '>Organization name</p>
            </div>
            <div>
                <p className='text-gray-600 dark:text-gray-300 my-auto text-sm font-medium '>{selectedCustomerData.organization_name}</p>
            </div>
            <div className='mt-2'>
                <p className='text-gray-500 dark:text-gray-400 my-auto text-sm font-medium '>Organization Description</p>
            </div>
            <div>
                <p className='text-gray-600 dark:text-gray-300 my-auto text-sm font-medium '>{selectedCustomerData.organization_description}</p>
            </div>
            <div className='mt-2'>
                <p className='text-gray-500 dark:text-gray-400 my-auto text-sm font-medium '>Shipping Information</p>
            </div>
            <div className='flex flex-col'>
                <p className='text-gray-600 dark:text-gray-300 my-auto text-sm font-medium '>{selectedCustomerData.address_street_line_1}</p>
                <p className='text-gray-600 dark:text-gray-300 my-auto text-sm font-medium '>{selectedCustomerData.address_street_line_2}</p>
                <p className='text-gray-600 dark:text-gray-300 my-auto text-sm font-medium '>{`${selectedCustomerData.address_city}, ${selectedCustomerData.address_state} ${selectedCustomerData.address_zip_code} ${selectedCustomerData.address_country}`}</p>
                <p className='text-gray-600 dark:text-gray-300 my-auto text-sm font-medium italic pt-0.5'>{selectedCustomerData.address_email}</p>
                <p className='text-gray-600 dark:text-gray-300 my-auto text-sm font-medium italic'>{selectedCustomerData.address_phone_number}</p>
            </div>
        </div>
        <div className='mt-10'>
            <div>
                <p className='text-gray-600 dark:text-gray-200 my-auto text-base font-medium '>Key Personnel</p>
            </div>
            <table className="table-auto w-full ">
                <thead >
                    <tr className='border-b-[0.01em] dark:border-zinc-600 text-left'>
                        <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 text-left'>Name</th>
                        <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 text-left'>Role</th>
                        <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 text-left'>Description</th>
                    </tr>
                </thead>
                <tbody >
                    <tr className='border-b-[0.01em]  dark:border-zinc-500 cursor-pointer hover:bg-slate-100 dark:hover:bg-slate-800' >
                        <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3'>John Smith</td>
                        <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3'>Manufacturing Engineer</td>
                        <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3'>Some description about this person</td>
                    </tr>
                    <tr className='border-b-[0.01em]  dark:border-zinc-500 cursor-pointer hover:bg-slate-100 dark:hover:bg-slate-800' >
                        <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3'>Luke Mazak</td>
                        <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3'>Manufacturing Manager</td>
                        <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3'>Some description about this person</td>
                    </tr>
                </tbody>
            </table>

        </div>
    </div>
  )
}

export default CustomerInfoCard