import {Auth } from 'aws-amplify';
import { CognitoJwtVerifier } from "aws-jwt-verify";





  export async function getData(url = '') {
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let godViewEnable = urlParams.get('is_god_view_enabled')

    var authString = await getToken();
    
    const response = await fetch(url, {
      method: 'GET', 
      mode: 'cors', 
      cache: 'no-cache', 
      credentials: 'same-origin', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': authString,
        // 'iotflows-member-role': selected_iotflows_member_role_id,
        'iotflows-member-role': localStorage.getItem('iotflows_member_role_id') || (godViewEnable ? 'IOTFLOWS_GOD' : '')
      },
      redirect: 'follow', 
      referrerPolicy: 'no-referrer', 
    });
    return response; 
  }

  export async function postData(url = '', data = {}) {
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let godViewEnable = urlParams.get('is_god_view_enabled')

    var authString = await getToken();

    const response = await fetch(url, {
      method: 'POST', 
      mode: 'cors', 
      cache: 'no-cache', 
      credentials: 'same-origin', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': authString,
        // 'iotflows-member-role': selected_iotflows_member_role_id,
        'iotflows-member-role': localStorage.getItem('iotflows_member_role_id') || (godViewEnable ? 'IOTFLOWS_GOD' : '')
      },
      body: JSON.stringify(data),
      redirect: 'follow', 
      referrerPolicy: 'no-referrer', 
    });
    return response; 
  }

  export async function postDataText(url = '', data = {}) {
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let godViewEnable = urlParams.get('is_god_view_enabled')

    var authString = await getToken();

    const response = await fetch(url, {
      method: 'POST', 
      mode: 'cors', 
      cache: 'no-cache', 
      credentials: 'same-origin', 
      headers: {
        'Content-Type': 'text/html; charset=UTF-8',
        'Authorization': authString,
        // 'iotflows-member-role': selected_iotflows_member_role_id,
        'iotflows-member-role': localStorage.getItem('iotflows_member_role_id') || (godViewEnable ? 'IOTFLOWS_GOD' : '')
      },
      body: data,
      redirect: 'follow', 
      referrerPolicy: 'no-referrer', 
    });
    return response; 
  }


  export async function postDataProfilePic(url = '', data = '') {
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let godViewEnable = urlParams.get('is_god_view_enabled')


    var authString = await getToken();

    const response = await fetch(url, {
      method: 'POST', 
      mode: 'cors', 
      cache: 'no-cache', 
      credentials: 'same-origin', 
      headers: {
        'Authorization': authString,
        // 'iotflows-member-role': selected_iotflows_member_role_id,
        'iotflows-member-role': localStorage.getItem('iotflows_member_role_id') || (godViewEnable ? 'IOTFLOWS_GOD' : '')
      },
      body: data,
      redirect: 'follow', 
      referrerPolicy: 'no-referrer', 
    });
    return response; 
  }


  export async function putData(url = '', data = {}) {
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let godViewEnable = urlParams.get('is_god_view_enabled')

    var authString = await getToken();

    const response = await fetch(url, {
      method: 'PUT', 
      mode: 'cors', 
      cache: 'no-cache', 
      credentials: 'same-origin', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': authString,
        // 'iotflows-member-role': selected_iotflows_member_role_id,
        'iotflows-member-role': localStorage.getItem('iotflows_member_role_id') || (godViewEnable ? 'IOTFLOWS_GOD' : '')
      },
      body: JSON.stringify(data),
      redirect: 'follow', 
      referrerPolicy: 'no-referrer', 
    });
    return response; 
  }

  export async function deleteData(url = '', data = {}) {
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let godViewEnable = urlParams.get('is_god_view_enabled')

    var authString = await getToken();

    const response = await fetch(url, {
      method: 'DELETE', 
      mode: 'cors', 
      cache: 'no-cache', 
      credentials: 'same-origin', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': authString,
        // 'iotflows-member-role': selected_iotflows_member_role_id,
        'iotflows-member-role': localStorage.getItem('iotflows_member_role_id') || (godViewEnable ? 'IOTFLOWS_GOD' : '')
      },
      body: JSON.stringify(data),
      redirect: 'follow', 
      referrerPolicy: 'no-referrer', 
    });
    return response; 
  }


  

  export async function updateUserPhoneNumber(phone_number) {
    let user = await Auth.currentAuthenticatedUser();
    let response = await Auth.updateUserAttributes(user, {'phone_number': phone_number});
    if(response == 'SUCCESS')
    {
      // verify the phone number by generating a code
      Auth.verifyCurrentUserAttribute('phone_number');      
    }    
    return response; 
  }


  export async function verifyUserPhoneNumber(code) 
  {
    let response = await Auth.verifyCurrentUserAttributeSubmit('phone_number', code);
    return response; 
  }
  
  


  function getToken() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return new Promise(async resolve => {
      var authString = "";
      try {

        // Read tokens:
        // 1. accessToken: read token from the login screen
        let accessToken = ""
        try {
          accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
        } catch(e){}
        
        // 2. url_token: read token from the url (e.g. for TVs) that shouldn't be stored in the session      
        let urlToken = urlParams.get('url_token')

        // 3. passedToken: check if we're in a native app and token is passed to us
        // first check if a token is passed
        var passedToken = urlParams.get('passed_token')        
        if(passedToken)
        {
          // store it in the local storage
          localStorage.setItem('passedToken', passedToken)                    
        }
        else
        {
          // if not found in the url, try reading it from the local storage
          let tempToken = localStorage.getItem('passedToken')     
          if(tempToken)
          {            
            // Verify Access Token for app: console_iotflows_com_new_with_totp
            try 
            {
              const verifier = CognitoJwtVerifier.create({
                userPoolId: "us-east-1_3EP1MRzAy",
                tokenUse: "access",
                clientId: "18230ih88kfb3ouj67mrid5er3", 
              });  
              // verify if token is valid and not expired
              const payload = await verifier.verify(tempToken);                          
              passedToken = tempToken
            } 
            catch {  }   

            // Verify Access Token for app: ios-app  
            try 
            {
              const verifier = CognitoJwtVerifier.create({
                userPoolId: "us-east-1_3EP1MRzAy",
                tokenUse: "access",
                clientId: "4gv9m1g4m7mnrcsacav01b5nad", 
              });  
              // verify if token is valid and not expired
              const payload = await verifier.verify(tempToken);                          
              passedToken = tempToken
            } 
            catch {  }   

          }        
          
        }

        // prioritized passed token over actual login
        if (passedToken)
        {
          authString = 'Bearer ' + passedToken
        }
        else if (urlToken)
        {
          authString = 'Basic ' + urlToken
        }   
        else if(accessToken)
        {
          authString = 'Bearer ' + accessToken
        }            
        // console.log('authString', authString)
      }
      catch(e)
      {
        window.location.reload(false);
      }
      resolve(authString);
    });
  }
  
