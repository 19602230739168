import React from 'react'

function RoutersTable() {
  return (
    <div>
        <div className='w-full px-2 pb-4 overflow-scroll scrollbar-hide bg-slate-50 dark:bg-slate-700 rounded-3xl'>
            <h1 className='w-full text-gray-600 dark:text-gray-200 font-semibold text-2xl mt-4  ml-1'>
                Routers
            </h1>
            <table className="table-auto w-full ">
                <thead >
                <tr className='border-b-[0.01em] dark:border-zinc-600 text-left'>
                    <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 text-left'>Serial</th>
                    <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 text-left'>Mac</th>
                    <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 text-left'>Model</th>
                    <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 text-left'>IMEI</th>
                    <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 text-left'>Firmware Version</th>
                    <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 text-left'>IMSI</th>
                    <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 text-left'>ICCID</th>
                    <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 text-right'>Onboarded</th>
                </tr>
                </thead>
                <tbody >
                    <tr className='border-b-[0.01em]  dark:border-zinc-500 cursor-pointer hover:bg-slate-100 dark:hover:bg-slate-800' >
                        <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3'>6000644270</td>
                        <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3 text-left'>20:97:27:0F:59:EF</td>
                        <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3 text-left'>RUT950</td>
                        <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3 text-left'>864839048442673</td>
                        <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3 text-left'>{`RUT9_R_00.07.02.4`}</td>
                        <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3 text-left'>232031722828910</td>
                        <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3 text-left'>89430301722228289102</td>
                        <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3 text-right'>2024-03-05 09:29:18</td>
                    </tr>
                {/* {filteredTableData.map((customer, index) => (
                <tr key={customer.organization_uuid} className='border-b-[0.01em]  dark:border-zinc-500 cursor-pointer hover:bg-slate-100 dark:hover:bg-slate-800' onClick={() => navigate(`/customer/selected-customer/${customer.organization_uuid}`)}>
                    <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3'>{customer.organization_name}</td>
                    <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3 text-right'>{customer.senseai_count}</td>
                    <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3 text-right'>{`NA`}</td>
                    <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3 text-right'>{customer.actual_arr}</td>
                    <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3 text-right'>{customer.arr}</td>
                    <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3 text-right'>{customer.location}</td>
                    <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3 text-right'>{customer.lifespan}</td>
                    <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3 text-right'>{formatDate(customer.created_at)}</td>
                </tr>
                ))} */}
                </tbody>
            </table>
        </div>
    </div>
  )
}

export default RoutersTable