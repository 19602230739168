import React from 'react'

function SelectedCustomerMetrics({selectedCustomerData}) {

    const formatDate = (date) => {
        if(date){
            const options = {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
              };
              
              const formatter = new Intl.DateTimeFormat('en-US', options); // Adjust locale if needed
              const formattedDate = formatter.format(new Date(date));

              return formattedDate
        }
    }

    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

  return (
    <div className='flex flex-col w-full bg-slate-50 dark:bg-slate-700 rounded-3xl px-4 py-6 mt-2'>
        <div className='grid grid-cols-2 sm:grid-cols-3 gap-2 md:grid-cols-4 justify-around mt-1'>
            <div className='flex flex-col text-center'>
                <div className='text-gray-500 dark:text-gray-400 text-base lg:text-lg font-medium'>ARR</div>
                <div className='text-green-600 dark:text-green-500 text-2xl lg:text-2xl font-medium '>{USDollar.format(selectedCustomerData.actual_arr)}</div>
            </div>
            <div className='flex flex-col text-center'>
                <div className='text-gray-500 dark:text-gray-400 text-base lg:text-lg font-medium'>Total Spend</div>
                <div className='text-green-600 dark:text-green-500 text-2xl lg:text-2xl font-medium '>{USDollar.format(selectedCustomerData.organization_total_amount_paid)}</div>
            </div>
            <div className='flex flex-col text-center'>
                <div className='text-gray-500 dark:text-gray-400 text-base lg:text-lg font-medium'>Devices</div>
                <div className='text-gray-600 dark:text-gray-200 text-2xl lg:text-2xl font-medium '>{selectedCustomerData.senseai_count}</div>
            </div>
            <div className='flex flex-col text-center'>
                <div className='text-gray-500 dark:text-gray-400 text-base lg:text-lg font-medium'>Routers</div>
                <div className='text-gray-600 dark:text-gray-200 text-2xl lg:text-2xl font-medium '>{`--`}</div>
            </div>
            <div className='flex flex-col text-center'>
                <div className='text-gray-500 dark:text-gray-400 text-base lg:text-lg font-medium'>Location</div>
                <div className='text-gray-600 dark:text-gray-200 text-2xl lg:text-2xl font-medium '>{selectedCustomerData.location}</div>
            </div>
            <div className='flex flex-col text-center'>
                <div className='text-gray-500 dark:text-gray-400 text-base lg:text-lg font-medium'>Lifespan</div>
                <div className='text-gray-600 dark:text-gray-200 text-2xl lg:text-2xl font-medium '>{selectedCustomerData.lifespan}</div>
                {/* <div className='text-gray-500 dark:text-gray-400 text-sm font-light '>{`Sep 4, 2023`}</div> */}
            </div>
            <div className='flex flex-col text-center'>
                <div className='text-gray-500 dark:text-gray-400 text-base lg:text-lg font-medium'>Started</div>
                <div className='text-gray-600 dark:text-gray-200 text-2xl lg:text-2xl font-medium '>{formatDate(selectedCustomerData.created_at)}</div>
                {/* <div className='text-gray-500 dark:text-gray-400 text-sm font-light '>{`Sep 4, 2023`}</div> */}
            </div>

        </div>
    </div>
  )
}

export default SelectedCustomerMetrics