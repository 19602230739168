import React from 'react'

function LocationTable({stateData}) {

    const colorPallet = [ '#c7d2fe', '#60a5fa', '#38bdf8', '#818cf8', '#7dd3fc', '#a5b4fc', '#bae6fd', '#93c5fd', '#bfdbfe', ]

    const selectColor = (index) => {
        return{
            backgroundColor: colorPallet[index],
          }
    }
  return (
    <div className='w-full px-2 overflow-scroll scrollbar-hide bg-slate-50 dark:bg-slate-700 rounded-3xl h-[400px] 2xl:h-[600px]'>
    <table className="table-auto w-full ">
        <thead >
          <tr className='border-b-[0.01em] dark:border-zinc-600 text-left'>
            <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 text-left'>Country</th>
            <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 text-left'>State</th>
            <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 text-right'>Count</th>
            <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 text-right'>Devices</th>
            <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 text-left'>{`Organizations`}</th>
          </tr>
        </thead>
        <tbody >
        {stateData.map((state, index) => (
          <tr key={index} className='border-b-[0.01em]  dark:border-zinc-500 '>
            <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3'>{state.address_country}</td>
            <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3'>{state.address_state}</td>
            <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3 text-right'>{state.organization_count}</td>
            <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3 text-right'>{state.device_count}</td>
            <td className='flex items-center pt-2.5 px-2'>
                    {state.organizations.map((org, index) => (
                        <div key={`${org}-${index}`} style={selectColor(index)}  className='max-w-[150px] truncate rounded-full mr-2'>
                            <span className='px-2 py-0.5 m-1 text-gray-800 text-sm'>{org}</span>
                        </div>
                    ))} 
            </td>
          </tr>
        ))}
        </tbody>
      </table>
  </div>
  )
}

export default LocationTable