import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import DevicesTable from './DevicesTable'
import RoutersTable from './RoutersTable'
import CustomerInfoCard from './CustomerInfoCard'
import SelectedCustomerMetrics from './SelectedCustomerMetrics'
import NotesTable from './NotesTable'
import { getData } from '../ApiCalls/DataApis'
import { apiLink } from '../ApiCalls/ApisVariables'

function SelectedCustomerPage() {
    const { organization_uuid } = useParams()
    const navigate = useNavigate()
    const [selectedCustomerData, setSelectedCustomerData] = useState([])


    useEffect(() => {
        if(organization_uuid){
            getData(apiLink + '/v1/internal/dashboard/organizations/' + organization_uuid)
            .then(async response => {
              if(response && response.ok ){
                const data = await response.json();
                if(data && data.data){
                    // console.log('selected customer',data.data)
                    setSelectedCustomerData(data.data[0])
                } 
              }
            });
        }


    }, [organization_uuid])
  return (
    <div className={`px-4 sm:px-1 sm:pl-6 sm:pr-3 py-1 min-h-[calc(100vh-10rem)] max-h-[calc(100vh-5rem)] overflow-scroll scrollbar-hide`}>
        {selectedCustomerData && Object.keys(selectedCustomerData).length > 0 &&
        <>
            <nav className='mb-2 flex items-center'>
                <ol className='hidden sm:flex'>
                    <li className='cursor-pointer text-blue-500 dark:text-blue-400 hover:text-blue-600' onClick={() => navigate(`/customer`)}>Customers</li>
                    <li className='mx-2 text-gray-500 dark:text-gray-400'>/</li>
                    <li className='text-gray-500 dark:text-gray-400 truncate max-w-[150px]'>{selectedCustomerData.organization_name? selectedCustomerData.organization_name : ''}</li>
                    {/* <li className='text-gray-500 dark:text-gray-400 truncate max-w-[150px]'>{selectedAsset.asset_custom_name  ? selectedAsset.asset_custom_name :selectedAsset.machine_name}</li> */}
                </ol>
                <ol className='flex sm:hidden'>
                    <li className='cursor-pointer text-blue-500 dark:text-blue-400 hover:text-blue-600 flex text-lg items-center' onClick={() => navigate(`/customer`)}>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                            </svg>
                        </div>
                        <span>Customers</span>
                    </li>
                </ol>
            </nav>
            <div className='mb-2'>
                <SelectedCustomerMetrics selectedCustomerData={selectedCustomerData} />
            </div>
            <div className='flex flex-col sm:flex-row'>
                {/* Customer Information section */}
                <div className='flex flex-col sm:mr-2 '>
                    <CustomerInfoCard selectedCustomerData={selectedCustomerData}/>
                </div>
                <div className='flex-1 mt-2 sm:mt-0'>
                    <NotesTable />
                </div>
            </div>
            <div className='flex flex-col space-y-2 mt-2'>
                <DevicesTable />
                <RoutersTable />
            </div>
        </>
        }
    </div>
  )
}

export default SelectedCustomerPage