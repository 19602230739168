
import { useState, Fragment } from 'react'
import { Switch } from '@headlessui/react'
import { useEffect } from 'react'
import {Amplify, Auth, Hub } from 'aws-amplify'
import {auth} from '../data/AwsConfigData'
import QRcodeMFA from '../components/modals/QRcodeMFA'
import ImageZoomCrop from '../components/modals/ImageZoomCrop'
import { putData } from '../ApiCalls/DataApis'
import { apiLink } from '../ApiCalls/ApisVariables'
import toast from 'react-hot-toast';
import EditPhoneNumberModal from '../components/modals/EditPhoneNumberModal'
import { useLocation, useSearchParams } from 'react-router-dom'



Amplify.configure({
  Auth: auth
});


function ProfilePage({user, updateUserInformation}) {
    const [editProfile, setEditProfile] = useState(false)
    const [editAssignment, setEditAssignment] = useState(false)
    const [userUsername, setUserUsername] = useState('')
    const [userEmail, setUserEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [enableMFA, setEnableMFA] = useState(false)
    const [userInfo, setUserInfo] = useState(false)
    const [openQRcodeMFAmodal, setOpenQRcodeMFAmodal] = useState(false)
    const [loadingUserInfo, setLoadingUserInfo] = useState(false)
    const [isPhoneNumberModalOpen, setIsPhoneNumberModalOpen] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [isMobile, setIsMobile] = useState(false)
    const location = useLocation();

  

    useEffect(() => {

        let currentIsMobile = searchParams.get('platform')

        if(currentIsMobile === 'mobile'){
            setIsMobile(true)
        }else{
            setIsMobile(false)
        }
        

    },[])


    useEffect(() => {
        if(user){
            setUserUsername(user.user_username_public)
            setUserEmail(user.user_email)
            setFirstName(user.user_first_name)
            setLastName(user.user_last_name)
            setPhoneNumber(user.user_phone_number)
            if(isMobile){
                setEnableMFA(user.user_is_mfa_enabled)
            }
        }

    },[user])


    useEffect(() => {
        if(!isMobile){
            Auth.currentAuthenticatedUser()
            .then(user => {
                // console.log('user', user)
                setUserInfo(user)
            Auth.getPreferredMFA(user,{
                // Optional, by default is false. 
                // If set to true, it will get the MFA type from server side instead of from local cache.
                bypassCache: false 
            }).then((data) => {
                // console.log('Current preferred MFA type is: ' + data);
                if(data === 'SMS_MFA' || data === 'SOFTWARE_TOKEN_MFA' ){
                    setEnableMFA(true)
                }else {
                    console.log('No MFA enabled')
                }
            })
            })
            .catch(err => {
                
                console.log('error',err);
            });
        }
        
    }, [])

    const handleOpenModal = (enableAuth) => {
        setOpenQRcodeMFAmodal(false)
        setEnableMFA(enableAuth)
        
    }

    const handleEnabledMFA = () => {
        if(enableMFA){
            Auth.setPreferredMFA(userInfo, 'NOMFA')
            setEnableMFA(false)
        }else{
            setEnableMFA(true)
            setOpenQRcodeMFAmodal(true)
        }
    }


  const updateUserInfo = () => {
    try {
        if(!loadingUserInfo){
            setLoadingUserInfo(true)
            let currentSearch = location.search
            putData(apiLink +'/v1/users' + currentSearch, 
                {'user_first_name': firstName,
                'user_last_name': lastName,
                'user_username_public': userUsername})
                .then(async response => {
                    if(response.ok){
                        setEditProfile(false)
                        setLoadingUserInfo(false)
                        updateUserInformation()
                        toast.success('User information updated')
                    }
                    else{
                        try{
                            const data = await response.json();
                            toast.error('Error updating user information')
                            setLoadingUserInfo(false)
                        }catch (e){
                            this.props.showErrorSnackbar('Something went wrong');
                        }
                    }
                })


        }
    } catch (error) {
        console.log(error.message)
        setLoadingUserInfo(false)
    }
    
  }

  const handleEditPhoneNumberModal = () => {
    setIsPhoneNumberModalOpen(!isPhoneNumberModalOpen)

  }

  return (
    <div className={`pl-6 pr-6 py-1 ${isMobile ? 'max-h-[calc(100vh-1rem)]' : 'max-h-[calc(100vh-5rem)]'}  mx-auto overflow-scroll scrollbar-hide`}>
        {/* profile Section */}
        <div className="mt-4">
            <div className='flex justify-between border-b-[1px] border-slate-200 dark:border-slate-700 pb-4 items-center'>
                <span className='text-xl font-bold text-gray-800 dark:text-gray-300'>Profile</span>
                {editProfile ? (
                    <div className='flex space-x-2'>
                        <button className='flex text-base sm:text-sm text-gray-600 dark:text-gray-400 items-center border-[1px] border-gray-300 dark:border-gray-600 rounded-lg shadown-iotflows-lg px-2 py-0.5' onClick={() => setEditProfile(false)}>
                            <span >Cancel</span>
                        </button>
                        <button 
                            className='relative flex text-base sm:text-sm text-white bg-blue-600 dark:text-gray-100 items-center border-[1px] border-blue-600 dark:border-blue-600 rounded-lg shadown-iotflows-lg px-2 py-0.5' 
                            onClick={updateUserInfo}
                            disabled={loadingUserInfo}
                            >
                            <span >Save</span>
                            {loadingUserInfo && 
                                <div >
                                    <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                    </svg>
                                </div>
                              }
                        </button>
                    </div>

                ):(
                <button className='flex text-base sm:text-sm text-gray-600 dark:text-gray-400 items-center border-[1px] border-gray-300 dark:border-gray-600 rounded-lg shadown-iotflows-lg px-2 py-0.5' onClick={() => setEditProfile(true)}>
                    <span className='mr-1'>Edit</span>
                    <svg className='w-4 h-4' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                    </svg>

                </button>
                )}
            </div>

            <div className='flex flex-col space-y-4 mt-4 text-base ml-2'>
                <div className='flex grow justify-center sm:justify-start'>
                    <ImageZoomCrop 
                        title="Edit Profile Picture" 
                        isDisabled={editProfile} 
                        isRounded={true} 
                        currentPicture={user.user_image_url} 
                        submitType={'userProfilePic'}
                        updateInfo={updateUserInformation}
                    />
                </div>
                <div className='flex flex-col sm:flex-row'>
                    <div className='grow max-w-[320px] text-gray-600 dark:text-gray-400 my-auto text-sm font-medium '>Username</div>
                    <div className='flex-1 '>
                        <input 
                            className={`bg-transparent w-full max-w-[300px] border rounded-md py-2 px-1 sm:py-1 sm:px-3 shadow-sm text-lg sm:text-sm 
                                        focus:outline-none focus:bg-transparent focus:ring-1 focus:border-blue-300 focus:ring-blue-300
                                        dark:placeholder:text-gray-500 dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800 
                                        placeholder:text-gray-400 text-gray-600 border-gray-200 
                                        disabled:border-transparent disabled:shadow-none disabled:dark:border-transparent `} 
                            id={'userUsername'} 
                            type={'text'}
                            name={'userUsername'}
                            value={userUsername}
                            onChange={(e) => setUserUsername(e.target.value)}
                            disabled={!editProfile}
                            />
                    </div>
                </div>
                <div className='flex flex-col sm:flex-row'>
                    <div className='grow max-w-[320px] text-gray-600 dark:text-gray-400 my-auto text-sm font-medium'>First name</div>
                    <div className='flex-1 '>
                        <input 
                            className={`bg-transparent w-full max-w-[300px] border rounded-md py-2 px-1 sm:py-1 sm:px-3 shadow-sm text-lg sm:text-sm 
                            focus:outline-none focus:bg-transparent focus:ring-1 focus:border-blue-300 focus:ring-blue-300
                            dark:placeholder:text-gray-500 dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800 
                            placeholder:text-gray-400 text-gray-600 border-gray-200 
                            disabled:border-transparent disabled:shadow-none disabled:dark:border-transparent`} 
                            id={'firstName'} 
                            type={'text'}
                            name={'firstName'}
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            disabled={!editProfile}
                            />
                    </div>
                </div>
                <div className='flex flex-col sm:flex-row'>
                    <div className='grow max-w-[320px] text-gray-600 dark:text-gray-400 my-auto text-sm font-medium'>Last Name</div>
                    <div className='flex-1 '>
                        <input 
                            className={`bg-transparent w-full max-w-[300px] border rounded-md py-2 px-1 sm:py-1 sm:px-3 shadow-sm text-lg sm:text-sm 
                                        focus:outline-none focus:bg-transparent focus:ring-1 focus:border-blue-300 focus:ring-blue-300
                                        dark:placeholder:text-gray-500 dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800 
                                        placeholder:text-gray-400 text-gray-600 border-gray-200 
                                        disabled:border-transparent disabled:shadow-none disabled:dark:border-transparent`} 
                            id={'lastName'} 
                            type={'text'}
                            name={'lastName'}
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            disabled={!editProfile}
                            />
                    </div>

                </div>
                <div className='flex flex-col sm:flex-row'>
                    <div className='grow max-w-[320px] text-gray-600 dark:text-gray-400 my-auto text-sm font-medium '>Email</div>
                    <div className='flex-1'>
                        <input 
                            className={`bg-transparent w-full max-w-[300px] border rounded-md py-2 px-1 sm:py-1 sm:px-3 shadow-sm text-lg sm:text-sm 
                                        focus:outline-none focus:bg-transparent focus:ring-1 focus:border-blue-300 focus:ring-blue-300
                                        dark:placeholder:text-gray-500 dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800 
                                        placeholder:text-gray-400 text-gray-600 border-gray-200 
                                        disabled:border-transparent disabled:shadow-none disabled:dark:border-transparent `} 
                            id={'userEmail'} 
                            type={'text'}
                            name={'userEmail'}
                            value={userEmail}
                            onChange={(e) => setUserEmail(e.target.value)}
                            disabled={true}
                            />
                    </div>
                </div>
                <div className='flex flex-col sm:flex-row'>
                    <div className='grow max-w-[320px] text-gray-600 dark:text-gray-400 my-auto text-sm font-medium'>Phone Number</div>
                    <div className='flex-1 '>
                        <div className='flex '>
                            <input 
                            className={`bg-transparent w-full max-w-[300px] border rounded-md py-2 px-1 sm:py-1 sm:px-3 shadow-sm text-lg sm:text-sm 
                                        focus:outline-none focus:bg-transparent focus:ring-1 focus:border-blue-300 focus:ring-blue-300
                                        dark:placeholder:text-gray-500 dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800 
                                        placeholder:text-gray-400 text-gray-600 border-gray-200 
                                        disabled:border-transparent disabled:shadow-none disabled:dark:border-transparent`} 
                            id={'phoneNumber'} 
                            type={'tel'}
                            name={'phoneNumber'}
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            disabled={true}
                            />
                            {editProfile && 
                            <div>
                                <button className='flex text-base sm:text-sm text-gray-600 dark:text-gray-400 items-center border-[1px] border-gray-300 dark:border-gray-600 rounded-lg shadown-iotflows-lg px-2 py-0.5' onClick={handleEditPhoneNumberModal}>
                                    <span className='mr-1 hidden md:flex'>Edit Phone Number</span>
                                    <span className='mr-1 flex md:hidden'>Edit</span>
                                    <svg className='w-4 h-4' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                    </svg>
                                </button>
                            </div>
                            }
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div className={`mt-16`}>
            <div className='flex justify-between border-b-[1px] border-slate-200 dark:border-slate-700 pb-4 items-center'>
                <div className='flex flex-col'>
                    <span className='text-lg font-bold text-gray-800 dark:text-gray-300'>Two-step authentication</span>
                    <span className='text-base sm:text-sm font-medium text-gray-500 dark:text-gray-400'>Increase security for your account by using authentication app as added layer of security.</span>
                </div>
            </div>
            <div className='flex justify-between border-b-[1px] border-slate-200 dark:border-slate-700 pb-2 items-center mt-2' >
                <div className='flex flex-col'>
                    <span className='text-base sm:text-sm font-medium text-gray-500 dark:text-gray-400'>Authentication App</span>
                    {enableMFA ? (
                        <span className='text-base sm:text-sm font-normal text-green-600 dark:text-green-400'>Enabled</span>
                    ):(
                        <span className='text-base sm:text-sm font-normal text-rose-600 dark:text-rose-400'>Disabled</span>
                    )}
                </div>
                <div>
                <Switch checked={enableMFA} onChange={handleEnabledMFA} as={Fragment} disabled={isMobile}>
                    {({ checked }) => (
                        /* Use the `checked` state to conditionally style the button. */
                        <button
                        className={`${
                            checked ? 'bg-green-500' : 'bg-gray-300'
                        } relative inline-flex h-6 w-11 items-center rounded-full outline-none focus:outline-none`}
                        >
                        <span className="sr-only">Enable Two Factor Authentication</span>
                        <span
                            className={`${
                            checked ? 'translate-x-6' : 'translate-x-1'
                            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                        />
                        </button>
                    )}
                </Switch>
                </div>
            </div>
        </div>

        {enableMFA && openQRcodeMFAmodal &&
            <QRcodeMFA userInfo={userInfo} handleOpenModal={handleOpenModal} remoteOpenModal={openQRcodeMFAmodal} />
        }
        {isPhoneNumberModalOpen && 
            <EditPhoneNumberModal remoteOpenModal={isPhoneNumberModalOpen} handleEditPhoneNumberModal={handleEditPhoneNumberModal} updateInfo={updateUserInformation} />
        }
    </div>
  )
}

export default ProfilePage