import { Toaster } from 'react-hot-toast';
import { useEffect, useState } from "react";
import { getData } from "./ApiCalls/DataApis";
import { apiLink } from "./ApiCalls/ApisVariables";
import Header from "./components/Header";
import { useRecoilState } from "recoil";
import {darkModeState} from './atoms/darkModeState'
import IoTFlowsLogoLightTransparent from "./images/IoTFlowsLogoLightTransparent.png";
import { Routes, Route, useLocation } from "react-router-dom";
import {userInfo} from './atoms/userStates'
import ProfilePage from "./MySettingsPage/ProfilePage";
import MainKpisPage from './MyKpisPage/MainKpisPage';
import Nav from "./components/Nav";
import EmailTrackingPage from './MyEmailManagementPage/EmailTrackingPage';
import LeadsTrackingPage from './MyLeadsPage/LeadsTrackingPage';
import CustomersPage from './MyCustomersPage/CustomersPage';
import SelectedCustomerPage from './MyCustomersPage/SelectedCustomerPage';

function App() {
  const [isDark, setIsDark] = useRecoilState(darkModeState)
  const [user, setUser] = useRecoilState(userInfo)
  let location = useLocation();
  const [hasAccess, setHasAccess] = useState(false)


    useEffect(() => {
      // Determining if user had GOD view access
      getData(apiLink + '/v1/users/iotflows')
      .then(async response => {
        if(response && response.ok ){
          const data = await response.json();
          if(data && data.data.length > 0 && data.data[0].iotflows_member_role_id){
            setHasAccess(true)
          } 
        }
      });

    }, [])

  useEffect(() => {
    updateUserInformation()
  }, [])

  

  const updateUserInformation = () => {
    
    let currentSearch = location.search

    getData(apiLink + '/v1/users' + currentSearch)
    .then(async response => {
      if(response && response.ok ){
        const data = await response.json();
        if(data && data.data.length > 0 && data.data[0]){
          setUser(data.data[0])
          if(data.data[0].user_prefers_dark_mode){
            setIsDark(true)
          }else{
            setIsDark(false)
          }
        } 
      }
    });
  }
  return (
    <div className={`${isDark? 'dark': ''} relative scrollbar-hide`}>
      <>
        <Toaster />
        <div className={` bg-slate-200 dark:bg-slate-900  max-h-screen h-screen overflow-hidden`}>
          <Header  user={user}/>
          <Nav />
          <main className={`${!hasAccess && 'hidden'}`}>
              <Routes>
                <Route path="/" element={<MainKpisPage />} />
                <Route path="/customer" element={<CustomersPage />} />
                <Route path="/customer/selected-customer/:organization_uuid" element={<SelectedCustomerPage />} />
                <Route path="/leads" element={<LeadsTrackingPage />} />
                <Route path="/emails" element={<EmailTrackingPage />} />
                <Route path="/settings/user" element={<ProfilePage user={user} updateUserInformation={updateUserInformation} />} />

              </Routes>
          </main>
        </div>
      </>
      </div>
  );
}

export default App;
