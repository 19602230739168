import React, { useState } from "react";
import useTable from "../components/hooks/useTable";
import TableFooter from "../components/TableFooter";
import LeadsTableRow from "./LeadsTableRow";

function LeadsTable({ data, rowsPerPage, leadsStages, setLeadsList}) {
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(data, page, rowsPerPage);
    const [mainSearchText, setMainSearchText] = useState('')
    const [searchData, setSearchData] = useState([])

    
  return (
    <>
      <div className='grid grid-cols-10 gap-4'>
        <div className='col-span-10 md:col-span-10 ml-auto '>
            <TableFooter range={range} slice={slice} setPage={setPage} page={page} rowsPerPage={rowsPerPage} data={data} />
        </div>
      </div>
      <table className="table-auto w-full">
            {/* <colgroup >
                <col className='w-[125px]'/>
                <col className='w-[300px]'/>
                <col className='w-full'/>
                <col className='w-full'/>
                <col className='w-full'/>
                <col className='w-full'/>
                <col className='w-full'/>
                <col className='w-full'/>
                <col className='w-full'/>
                <col className='w-full'/>
                
            </colgroup> */}
        <thead >
            <tr className='border-b-[0.01em] dark:border-slate-600 text-left'>
                <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-0.5 py-3'>{`Created`}</th>
                <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-0.5 py-3 text-center'>Assigned</th>
                <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 pl-12 pr-0.5 py-3'>Customer</th>
                <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-0.5 py-3'>Company</th>
                {/* <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-0.5 py-3 text-center'>Rating</th> */}
                <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-0.5 py-3'>Stage</th>
                <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-0.5 py-3 text-center'>Auto Emails</th>
                <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-0.5 py-3 text-center'>Reset Email</th>
                <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-0.5 py-3 text-center grow'>Tags</th>
                <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-0.5 py-3 text-center'>Notes</th>
            </tr>
        </thead>
          <tbody >
          {slice.map((el) => (
            <LeadsTableRow el={el}  key={el.lead_id} leadsStages={leadsStages} leadsList={data} setLeadsList={setLeadsList} />
          ))}
        </tbody>
      </table>
      
    </>
  )
}

export default LeadsTable