import { useEffect } from 'react'
import { useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { motion } from "framer-motion"
import { useLocation, useSearchParams } from 'react-router-dom'
import { getData } from '../../ApiCalls/DataApis'
import { apiLink } from '../../ApiCalls/ApisVariables'


function AssignedFilterBtn() {
   const [allAssignees, setAllAssignees] = useState([])
   const [selectedAssignees, setSelectedAssignees] = useState([])
   const [checkedAssigneesState, setCheckedAssigneesState] = useState([])
   const [searchParams, setSearchParams] = useSearchParams()
   const location = useLocation();


   useEffect(() =>{
        try {
            
                getData(apiLink + '/v1/internal/assignees' )
                .then(async response => {
                    // JSON data parsed by response.json() call
                    if(response.ok ){
                    const data = await response.json();
                        if(data && data.data){
                            setAllAssignees(data.data)
                        }
                    }
                })
            
            
        } catch (error) {
        console.log(error.message)
        }
    }, [])


   useEffect(() => {
    // Gettting search parameters from URL
        let currentSearch = location.search
        if(currentSearch){
            // Pulling all parts search parameters into an array
            let searchAssignees = searchParams.getAll('assignees')

            let updatedCheckedState = allAssignees.map((item, index) =>
                searchAssignees.includes(item.user_username) ? true : false
            );

            let selectedSearchedAssignees = [] 
            updatedCheckedState.map((checked,index) =>
                checked ? selectedSearchedAssignees.push(allAssignees[index]) : null
            )
            setSelectedAssignees(selectedSearchedAssignees)
            setCheckedAssigneesState(updatedCheckedState)
        }else {
            setCheckedAssigneesState(new Array(allAssignees.length).fill(false))
        }

    }, [location, allAssignees])

    // Currently donb't need because I'm just using the dates of the array and don't need the entire json of dep info
//    useEffect(() => {
//     let checkedDep = []
//         if(checkedAssigneesState.includes(true)){
//             allAssignees.map((dep, index) =>{
//                 if(checkedAssigneesState[index]){
//                     checkedDep.push(dep)
//                 }
//             })
//         }
//         console.log('checkedDep', checkedDep)
//    }, [checkedAssigneesState])


   const handleChangeAssigneeState = (position) => {
        let updatedCheckedState = checkedAssigneesState.map((item, index) =>
        index === position ? !item : item
        );
        setCheckedAssigneesState(updatedCheckedState)
        
    }

    const handleApplyFilter = () => {
        // putting all selected parts into the button for visualization
        let tempAssignee = []
        checkedAssigneesState.map((state, index) =>{
            if(state){
                tempAssignee.push(allAssignees[index]) 
            }
        })
        setSelectedAssignees(tempAssignee)

        // putting each part ID into an array to push it to the URL
        let parameters = []
        tempAssignee.map(dep => {
            parameters.push(dep.user_username)
        })

        let lead_tags = searchParams.getAll('lead_tags')
        let lead_stage_uuids = searchParams.getAll('lead_stage_uuids')
        let search = searchParams.getAll('search')
        
        setSearchParams({ assignees: parameters, lead_tags, lead_stage_uuids, search})
        
       
        
        

    }

  return (
    <div >
        <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`
                ${open ? 'bg-slate-100 dark:bg-slate-700' : ''}
                border border-blue-600 border-dashed rounded-full flex pl-1 pr-3 py-0.5 items-center outline-none max-w-[250px] `}
            >
                <div className='bg-white w-3 h-3 rounded-full m-1'>
                    <svg xmlns="http://www.w3.org/2000/svg" className={`transition ease-in-out duration-300 ${Object.keys(selectedAssignees).length > 0? ' rotate-45 ':'rotate-0'} h-5 w-5 ml-[-4px] mt-[-4px] text-blue-600 `} viewBox="0 0 20 20" fill="currentColor" >
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
                    </svg>
                </div>
                <div className='pl-1 text-sm text-gray-600 dark:text-gray-300'>
                    Assignees
                </div>
                {Object.keys(selectedAssignees).length > 0 && Object.keys(selectedAssignees).length < 3 &&
                <div className='flex items-center truncate'>
                    <div className='text-blue-600 pl-1 dark:text-blue-400 text-xs'>|</div>
                    {selectedAssignees.map((filtered,index) => (
                        <div key={filtered.user_username} className='flex '>
                        {index > 0 && <div className='text-blue-600 dark:text-blue-400 text-xs'>,</div>}
                        <div className='text-blue-600 dark:text-blue-400 pl-0.5 text-xs '>{filtered.user_first_name}</div>
                        </div>
                    ))}
                    <div className='flex items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className='text-gray-400 w-4 h-4'>
                            <path fillRule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clipRule="evenodd" />
                        </svg>
                    </div>

                </div>
                }
                {Object.keys(selectedAssignees).length >= 3 &&
                <div className='flex items-center truncate'>
                    <div className='text-blue-600 pl-1 dark:text-blue-400 text-xs'>|</div>
                    <div className='flex '>
                        <div className='text-blue-600 dark:text-blue-400 pl-0.5 text-xs '>{selectedAssignees[0].user_first_name} and {Object.keys(selectedAssignees).length - 1} more</div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className='text-gray-400 w-4 h-4'>
                        <path fillRule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clipRule="evenodd" />
                    </svg>
                </div>
                }
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-32 z-20 mt-3 w-[260px] -translate-x-1/2 transform ">
                <div className=" overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-slate-800 p-3">
                        <div className='text-sm font-bold text-gray-900 dark:text-gray-300'>Filter by Assignee</div>
                        {allAssignees.map((assignee, index)=>(
                            <div key={`${assignee.user_username}-${assignee.user_first_name}`} className="flex items-center my-3">
                                <input id={`assignee-checkbox-${index}`} type="checkbox" checked={checkedAssigneesState[index]} onChange={() => handleChangeAssigneeState(index)}  className="checkboxBtn"/>
                                <label htmlFor={`assignee-checkbox-${assignee.user_username}`} className="ml-2 text-xs font-medium text-gray-600 dark:text-gray-400">{assignee.user_first_name}</label>
                            </div>
                        ))}
                        

                        <motion.button  
                            whileHover={{ scale: 1.02 }} 
                            whileTap={{ scale: 0.98 }} 
                            onClick={ () => {
                                handleApplyFilter()
                                close()
                            }} 
                            className='w-full text-sm p-1 bg-blue-600 text-white rounded-md' >
                                Apply
                        </motion.button>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  )
}

export default AssignedFilterBtn