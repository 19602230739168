import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState, useCallback, useRef } from 'react'
import { motion, AnimatePresence } from "framer-motion";
import { useRecoilValue } from 'recoil';
import {darkModeState} from '../../atoms/darkModeState'
import Slider from '@mui/material/Slider';
import Cropper from 'react-easy-crop'
import { getCroppedImg, getRotatedImage, getRadianAngle, createImage, rotateSize } from './utils/canvasUtils'
import { getOrientation } from 'get-orientation/browser'
import { postDataProfilePic } from "../../ApiCalls/DataApis";
import { apiLink } from "../../ApiCalls/ApisVariables";
import toast from 'react-hot-toast';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';

const ORIENTATION_TO_ANGLE = {
  '3': 180,
  '6': 90,
  '8': -90,
}

function ImageZoomCrop({title, isDisabled, currentPicture, isRounded, submitType, updateInfo, uuid}) {
  const isDark = useRecoilValue(darkModeState);
  const [isOpen, setIsOpen] = useState(false)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const imageRed = useRef(null)
  const [imageSrc, setImageSrc] = useState(null)
  const [openImageZoomCropModal, setOpenImageZoomCropModal] = useState(false)
  const [loading, setLoading] = useState(false)

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
    // handleCloseModal(false)
    imageRed.current.value = null
  }


  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const handleBackButton = () => {
    setCroppedImage(null)
  }

  const handleChangeUserPic = () => {
    setIsOpen(true)
    imageRed.current.click()
  }

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      let file = e.target.files[0]
      let imageDataUrl = await readFile(file)


    //   apply rotation if needed
      let orientation = await getOrientation(file)
      let rotation = ORIENTATION_TO_ANGLE[orientation]
      if (rotation) {
        imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
      }
      setImageSrc(imageDataUrl)
      setOpenImageZoomCropModal(true)
    }
  }

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      )
      setCroppedImage(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }, [imageSrc, croppedAreaPixels, rotation])



  async function submitCroppedImg(
    imageSrc,
    pixelCrop,
    rotation = 0,
    flip = { horizontal: false, vertical: false }
  ) {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
  
    if (!ctx) {
      return null
    }
  
    const rotRad = getRadianAngle(rotation)
  
    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
      image.width,
      image.height,
      rotation
    )
  
    // set canvas size to match the bounding box
    canvas.width = bBoxWidth
    canvas.height = bBoxHeight
  
    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
    ctx.rotate(rotRad)
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
    ctx.translate(-image.width / 2, -image.height / 2)
  
    // draw rotated image
    ctx.drawImage(image, 0, 0)
  
    // croppedAreaPixels values are bounding box relative
    // extract the cropped image using these values
    const data = ctx.getImageData(
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height
    )
  
    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height
  
    // paste generated rotate image at the top left corner
    ctx.putImageData(data, 0, 0)
  
   
    // As a blob
    new Promise((resolve, reject) => {
      canvas.toBlob((file) => {
  
        const new_image_file = new FormData()
        new_image_file.append("file", file, "filename.jpg")
        
        
        if(!loading){
          setLoading(true)
          if(submitType === 'partImage'){
            let image = canvas.toDataURL()
            updateInfo(image)
            setLoading(false)
            setCroppedImage(null)
            closeModal()
          }
          if(submitType === 'userProfilePic'){
            postDataProfilePic(apiLink+ '/v1/users/profile/picture', new_image_file)
              .then(async response => {
                if(response.ok){
                  setLoading(false)
                  setCroppedImage(null)
                  closeModal()
                  updateInfo()
                  toast.success('Profile picture updated')
                  
                }
                else{
                  try{
                    const data = await response.json();
                    setLoading(false)
                    console.log(data.message);
                  }catch (e){
                    console.log('Something went wrong');
                    setLoading(false)
                  }
                }
              })
          }
          if(submitType === 'organizationLogo'){
            postDataProfilePic(apiLink+ '/v1/organizations/' + uuid + '/logo', new_image_file)
              .then(async response => {
                if(response.ok){
                  setLoading(false)
                  setCroppedImage(null)
                  closeModal()
                  updateInfo()
                  toast.success('Organization logo updated')
                  
                }
                else{
                  try{
                    const data = await response.json();
                    setLoading(false)
                    console.log(data.message);
                  }catch (e){
                    console.log('Something went wrong');
                    setLoading(false)
                  }
                }
              })

          }
        }
        
  
  
      }, 'image/jpeg',  95)
    })
  }

  return (
    <>
      <div >
        <input
            accept="image/*"
            type='file'
            className='hidden'
            ref={imageRed}
            onChange={onFileChange}
        />
        <button 
            className='disabled:cursor-default'
            disabled={!isDisabled}
            onClick={handleChangeUserPic}
        >
          {/* added the ? and String to clear cached image */}
          {!currentPicture && submitType === 'organizationLogo' && (
            <img
                src={'https://cdn-icons-png.flaticon.com/512/4812/4812244.png'}
                alt=""
                className={`h-20 w-20 ${isRounded ? 'rounded-full': 'rounded-md'}  ${isDisabled ? 'opacity-70': 'opacity-100'}`}
            />
          )}
          {!currentPicture && submitType === 'userProfilePic' && (
            <img
            src={'https://icons.veryicon.com/png/o/miscellaneous/two-color-icon-library/user-286.png'}
            alt=""
            className={`h-6 w-20 ${isRounded ? 'rounded-full': 'rounded-md'}  ${isDisabled ? 'opacity-70': 'opacity-100'}`}
        />
          )}
          {!currentPicture && submitType === 'partImage' && (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`h-16 w-16 ${isDark? 'text-gray-400 bg-zinc-700':' text-gray-600 bg-zinc-100'}  rounded-full ${isDisabled ? 'opacity-70': 'opacity-100'}`}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12a7.5 7.5 0 0015 0m-15 0a7.5 7.5 0 1115 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077l1.41-.513m14.095-5.13l1.41-.513M5.106 17.785l1.15-.964m11.49-9.642l1.149-.964M7.501 19.795l.75-1.3m7.5-12.99l.75-1.3m-6.063 16.658l.26-1.477m2.605-14.772l.26-1.477m0 17.726l-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205L12 12m6.894 5.785l-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864l-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495" />
            </svg>
          
          )}
          {currentPicture && submitType != 'partImage' &&
            <img
            src={currentPicture +'?'+ String(Math.random())}
            alt=""
            className={`h-20 w-20 ${isRounded ? 'rounded-full': 'rounded-md'}  ${isDisabled ? 'opacity-70': 'opacity-100'}`}
            />  
          }
          {currentPicture && submitType === 'partImage' &&
            <img
              src={currentPicture}
              alt=""
              className={`h-20 w-20 rounded-full bg-transparent  ${isDisabled ? 'opacity-70': 'opacity-100'}`}
            />  
          }
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" static onClose={() => null}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className={`w-full max-w-lg transform overflow-hidden rounded-2xl text-left align-middle shadow-xl transition-all ${isDark?'bg-zinc-800':'bg-white'}`}>
                  <Dialog.Title
                    as="h3"
                    className={`mb-1 p-4 text-lg font-medium leading-6 border-b-[1px] ${isDark?'text-gray-100 border-gray-700':'text-gray-700 border-gray-200'}`}
                  >
                    {title}
                  </Dialog.Title>
                  <AnimatePresence exitBeforeEnter>
                    {!croppedImage ? (
                      <motion.div
                        key='setImage'
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className='flex flex-col text-center'
                      > 
                        
                        <div className='relative w-full h-56 bg-gray-400'>
                          <Cropper
                            image={imageSrc}
                            crop={crop}
                            rotation={rotation}
                            zoom={zoom}
                            // cropShape="round"
                            aspect={1}
                            onCropChange={setCrop}
                            onRotationChange={setRotation}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                          />
                        </div>
                        <div className='p-4 flex flex-col items-stretch'>
                          <div className='flex items-center flex-1'>
                            <div
                              className={`${isDark?'text-gray-400 ':'text-gray-600 '} w-[85px] text-left`}
                            >
                              Zoom
                            </div>
                            <Slider
                              value={zoom}
                              min={1}
                              max={3}
                              step={0.1}
                              aria-labelledby="Zoom"
                              sx={{ml:4, padding:'22px 0px'}}
                              onChange={(e, zoom) => setZoom(zoom)}
                            />
                          </div>
                          <div className='flex items-center flex-1'>
                            <div className={`${isDark?'text-gray-400 ':'text-gray-600 '} w-[85px] text-left`}>
                              Rotation
                            </div>
                            <Slider
                              value={rotation}
                              min={0}
                              max={360}
                              step={1}
                              aria-labelledby="Rotation"
                              sx={{ml:4, padding:'22px 0px'}}
                              onChange={(e, rotation) => setRotation(rotation)}
                            />
                          </div>
                        </div>

                        <div className={`flex py-4 px-2 items-center mt-4 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                          <button
                            type="button"
                            className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-zinc-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                            onClick={closeModal}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className={`cursor-pointer ml-auto inline-flex rounded-md border border-transparent ${isDark?' text-blue-100 bg-blue-900 hover:bg-blue-800 disabled:bg-blue-700':'text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                            onClick={showCroppedImage}
                          >
                            Preview
                          </button>
                        </div>
                      </motion.div>
                    ):(
                      <motion.div
                        key='prevImg'
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className='flex flex-col text-center'
                      > 
                        
                        <div className='mx-auto'>
                          <img src={croppedImage} className='h-56 w-56'/>
                        </div>

                        <div className={`flex py-4 px-2 items-center mt-4 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                          <button
                            type="button"
                            className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-zinc-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                            onClick={closeModal}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className={`inline-flex ml-auto rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-zinc-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                            onClick={handleBackButton}
                          >
                            Back
                          </button>
                          <button
                            type="button"
                            className={`relative cursor-pointer ml-2 inline-flex rounded-md border border-transparent ${isDark?' text-blue-100 bg-blue-900 hover:bg-blue-800 disabled:bg-blue-700':'text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                            onClick={() => submitCroppedImg(imageSrc,croppedAreaPixels, rotation)}
                            // onClick={submitImage}
                            disabled={loading}
                          >
                            {'Submit'}
                            {loading && 
                                <div >
                                    <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin ${isDark?' fill-white text-gray-500 ':' fill-blue-600 text-gray-300 '}`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                    </svg>
                                </div>
                              }
                          </button>
                        </div>
                      </motion.div>

                    )}
                      
                      
                  </AnimatePresence>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default ImageZoomCrop


function GearIcon(props) {
  return (
      <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
          <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
          <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
      </svg>
    
    
  )
}