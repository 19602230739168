import { useRef, useState } from 'react'
import App from './App';
import LoginRoutes from './MyLoginPage/LoginRoutes';
import { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useLocation, useSearchParams } from 'react-router-dom'
// import MqttService from './core/services/MqttService';

function CredentialsRouting() {
    const [user, setUser] = useState(null)
    const [hasReadUser, setHasReadUser] = useState(true)
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams()
    const [token, setToken] = useState('')
    const dataFetchedRef = useRef(false);
    // const [isUserOnline, setIsUserOnline] = useState(true)


   

    useEffect(() => {
        let urlToken = searchParams.getAll('url_token')
        let passedToken = searchParams.getAll('passed_token')
        if(urlToken && urlToken[0]){
            setToken(urlToken[0])
        }else if(passedToken && passedToken[0]){
            setToken(passedToken[0])
        }
    }, [])
    
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(user => {
                setUser(user)
                setHasReadUser(true)
            })
            .catch(
                setHasReadUser(true)
            )

    }, [])

    useEffect(() => {
        if((user && Object.keys(user).length > 0) || token){
            if (dataFetchedRef.current) return;
            dataFetchedRef.current = true;
            // connectMQTTGlobally();
            
        }
    }, [token, user])

    // const connectMQTTGlobally = () => {
    //     // console.log('i fire once')
    //     MqttService.connectClient(
    //     mqttSuccessHandler,
    //     mqttConnectionLostHandler
    //     );
    // }

    // const mqttSuccessHandler = () => {
    //     console.log("connected to mqtt");
    //     setIsUserOnline(true)
    //   };
    
    //   const mqttConnectionLostHandler = () => {
    //     console.log("lost connection to mqtt");
    
    //     setIsUserOnline(false)
    //   };

    
    if(location && location.state && location.state.userLogin){
        // console.log('location.state.userLogin', location.state.userLogin)
        setUser(location.state.userLogin)
    }

  return (
    <div>
        {token || (hasReadUser && user && Object.keys(user).length > 0) ? (
            <App />
        ):(
            <LoginRoutes  />
        )}
    </div>
  )
}

export default CredentialsRouting