import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getData } from '../ApiCalls/DataApis'
import { apiLink } from '../ApiCalls/ApisVariables'

function DevicesTable() {
    const { organization_uuid } = useParams()
    const [sensorList, setSensorList] = useState([])

    useEffect(() => {
        if(organization_uuid){
            getData(apiLink + '/v1/organizations/'+ organization_uuid + '/nodes/senseai' )
            .then(async response => {
                // JSON data parsed by response.json() call
                if(response.ok ){
                const data = await response.json();
                    if(data && data.data){
                        // console.log('Sensor data', data.data)
                        setSensorList(data.data)
                    }
                }
            })
        }


    }, [organization_uuid])

    const formatDate = (date) => {
        if(date){
            const options = {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
              };
              
              const formatter = new Intl.DateTimeFormat('en-US', options); // Adjust locale if needed
              const formattedDate = formatter.format(new Date(date));

              const dateObject = new Date(date);
                const hours = dateObject.getHours().toString().padStart(2, '0'); // Pad with leading zeros if necessary
                const minutes = dateObject.getMinutes().toString().padStart(2, '0');
                const seconds = dateObject.getSeconds().toString().padStart(2, '0');
                const milliseconds = dateObject.getMilliseconds().toString().padStart(3, '0');

              return formattedDate + ' ' + hours + ':' + minutes + ':' + seconds
        }
    }


  return (
    <div>
        <div className='w-full px-2 pb-4 overflow-scroll scrollbar-hide bg-slate-50 dark:bg-slate-700 rounded-3xl'>
            <h1 className='w-full text-gray-600 dark:text-gray-200 font-semibold text-2xl mt-4  ml-1'>
                Devices
            </h1>
            <table className="table-auto w-full ">
                <thead >
                <tr className='border-b-[0.01em] dark:border-zinc-600 text-left'>
                    <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 text-left'></th>
                    <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 text-left'>Device</th>
                    <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 text-left'>ID</th>
                    <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 text-right'>Firmware Version</th>
                    <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 text-left'>WiFi SSID</th>
                    <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 text-right'>IP Address</th>
                    <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 text-right'>Onboarded</th>
                </tr>
                </thead>
                <tbody >
                {sensorList.map((sensor, index) => (
                    <tr key={sensor.node_uuid} className='border-b-[0.01em]  dark:border-zinc-500 hover:bg-slate-100 dark:hover:bg-slate-800' >
                        <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3'>
                        {sensor.node_is_online ? (
                            <div className="relative">
                                <div className="w-4 h-4 bg-transparent"></div>
                                <div className="absolute top-1 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full bg-green-400 dark:bg-green-600 animate-ping"></div>
                                <div className="absolute top-1 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full bg-green-400 dark:bg-green-600"></div>
                            </div>
                        ):(
                            <div className="relative">
                                <div className="w-4 h-4 bg-transparent"></div>
                                <div className={`absolute top-1 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full dark:bg-gray-400 bg-gray-600`}></div>
                                <div className={`absolute top-1 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full dark:bg-gray-400 bg-gray-600`}></div>
                            </div>
                        )}
                        </td>
                        <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3'>{sensor.node_name}</td>
                        <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3 text-left'>{sensor.node_uuid}</td>
                        <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3 text-right'>{sensor.node_firmware_version}</td>
                        <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3 text-left'>{sensor.node_vitals && sensor.node_vitals.wifi_ssid ? sensor.node_vitals.wifi_ssid : ''}</td>
                        <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3 text-right'>{sensor.node_vitals && sensor.node_vitals.wifi_local_ip ? sensor.node_vitals.wifi_local_ip : ''}</td>
                        <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3 text-right'>{formatDate(sensor.node_assigned_at)}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    </div>
  )
}

export default DevicesTable