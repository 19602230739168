// import HighchartsStock from 'highcharts/highstock';
// import highchartsMore from "highcharts/highcharts-more.js";
import HighchartsReact from "highcharts-react-official";
import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { darkModeState } from '../atoms/darkModeState';
import Highcharts from 'highcharts'


// highchartsMore(HighchartsStock);

function RevChurnStackedBarChart({revChurnDates, revChurnData}) {
    const [chartOptions, setChartOptions] = useState({
      chart: {
        backgroundColor: 'transparent',
        // height: '300px'
      },
      title: {
        text: ''
        },
      yAxis: {
          title: {
              text: ''
          }
      },
      credits : { enabled : false},
    })
    const isDark = useRecoilValue(darkModeState);

    

    useEffect(() => {
        if(revChurnData && Object.keys(revChurnData).length > 0){
            let retained = []
            let churn = []
            let newA = []
            let expanded = []
            let contraction = []
            revChurnData.map(rev => {
                // mrr.push(Number(devices.senseai_assigned_count) * 83 * 12)
                churn.push(Number(rev.churned_arr)) // calculated from the backend
                newA.push(Number(rev.added_arr)) // calculated from the backend
                expanded.push(Number(rev.expansion_arr)) // calculated from the backend
                contraction.push(Number(rev.contraction_arr)) // calculated from the backend
                let revArr = Number(rev.arr) - Number(rev.added_arr) - Number(rev.expansion_arr)
                retained.push(Number(revArr)) // calculated from the backend
            })
            setChartOptions({
              chart: {
                type: 'column',
                zoomType: 'x',
                backgroundColor:'transparent',
              },
              title: {
                text: ''
              },
              xAxis: {
                categories: revChurnDates,
                labels: {
                    style: {
                        color: isDark ? '#d1d5db':'#4b5563'
                    }
                }
              },
              yAxis: {
                title: {
                    text: ''
                },
              },
              yAxis:[{ // Primary yAxis
                labels: {
                  style: {
                    color: isDark ? '#d1d5db':'#4b5563'
                  },
                  formatter: function () {
                    return formatMoney.format(this.value);  // Format Y-axis labels as currency
                  }
                },
                plotLines: [{
                    value: 0,
                    width: 0.5,
                    color: isDark ? '#d1d5db':'#4b5563'
                }],
                title: {
                    text: '',
                  },
                lineWidth: 0,
                minorGridLineWidth: 0,
                lineColor: 'transparent',
                // labels: {
                //     enabled: false
                // },
                // min:0,
                minorTickLength: 0,
                tickLength: 0,
                gridLineWidth: 0,
        
              }],
              legend: {
                  layout: 'horizontal',
                  align: 'center',
                  verticalAlign: 'bottom',
                  itemStyle: {
                    fontSize:'14px',
                    color: isDark ? '#d1d5db':'#4b5563'
                 },

              },
              plotOptions: {
                column: {
                    stacking: 'normal',
                    backgroundColor:'#F3F4F6',
                    dataLabels: {
                        enabled: true
                    },
                    borderWidth:0
                },
                series: {
                  dataLabels: {
                      enabled: true,
                      style: {
                          color: '#FFFFFF',  // Change color of the data label text
                          fontSize: '13px',  // Example of changing font size
                      },
                      formatter: function () {
                        return  formatMoney.format(this.y);  // Format data labels as currency
                      },
                  }
              }
              },
              tooltip: {
                shared: true,
                formatter: function () {
                    const retained = this.points.find(p => p.series.name === 'Retained ARR')?.y || 0;
                    const newVal = this.points.find(p => p.series.name === 'New ARR')?.y || 0;
                    const expansionVal = this.points.find(p => p.series.name === 'Expansion ARR')?.y || 0;
                    const total = retained + newVal + expansionVal;
        
                    let tooltip = `<b>${this.x}</b><br/>`;
                    this.points.forEach(function (point) {
                        tooltip += `<span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${': ' + formatMoney.format(point.y)}</b><br/>`;
                    })
                    tooltip += `<br/><b>Total: ${formatMoney.format(total)} </b>`;
        
                    return tooltip;
                }
              },
              series: [
              //   {
              //     name: '',
              //     color: isDark ? '#374151' :'#F3F4F6',
              //     data: backgroundSeries,
              //     dataLabels: {
              //         enabled: false
              //     },
              //     enableMouseTracking: false,
              //     yAxis: 1
              // },
              {
                name: 'New ARR',
                color:'#16a34a',
                // color: {
                //   linearGradient: {
                //       x1: 0,
                //       y1: 0,
                //       x2: 0,
                //       y2: 1
                //   },
                //   stops: [
                //       [0, isDark ? '#4ade80' : '#86efac'],  // The top color of the gradient (your main color)
                //       [1, isDark ? '#16a34a' : '#22c55e']   // The bottom color (you can adjust this for a darker shade)
                //   ]
                // },
                data: newA
              },{
                name: 'Expansion ARR',
                color:'#065f46',
                // color: {
                //   linearGradient: {
                //       x1: 0,
                //       y1: 0,
                //       x2: 0,
                //       y2: 1
                //   },
                //   stops: [
                //       [0, isDark ? '#fb923c' : '#fdba74'],  // The top color of the gradient (your main color)
                //       [1, isDark ? '#ea580c' : '#f97316']   // The bottom color (you can adjust this for a darker shade)
                //   ]
                // },
                data: expanded
              },{
                  name: 'Retained ARR',
                  color:'#3b82f6',
                  // color: {
                  //   linearGradient: {
                  //       x1: 0,
                  //       y1: 0,
                  //       x2: 0,
                  //       y2: 1
                  //   },
                  //   stops: [
                  //       [0, isDark ? 'rgba(29, 78, 216, 0.9)':'rgba(37, 99, 235, 0.7)' ],  // The top color of the gradient (your main color)
                  //       [1, isDark ? '#3b82f6':'#3b82f6' ]   // The bottom color (you can adjust this for a darker shade)
                  //   ]
                  // },
                  data: retained
              },{
                name: 'Contraction',
                data: contraction,
                color:'#ea580c'
                // color: {
                //   linearGradient: {
                //       x1: 0,
                //       y1: 0,
                //       x2: 0,
                //       y2: 1
                //   },
                //   stops: [
                //       [0, isDark ? '#f87171' : '#fca5a5'],  // The top color of the gradient (your main color)
                //       [1, isDark ? '#dc2626' : '#ef4444']   // The bottom color (you can adjust this for a darker shade)
                //   ]
                // },
            },{
                  name: 'Churn',
                  data: churn,
                  color:'#dc2626'
                  // color: {
                  //   linearGradient: {
                  //       x1: 0,
                  //       y1: 0,
                  //       x2: 0,
                  //       y2: 1
                  //   },
                  //   stops: [
                  //       [0, isDark ? '#f87171' : '#fca5a5'],  // The top color of the gradient (your main color)
                  //       [1, isDark ? '#dc2626' : '#ef4444']   // The bottom color (you can adjust this for a darker shade)
                  //   ]
                  // },
              }]
            })
        }

    }, [revChurnData, isDark])

    const formatMoney = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    
      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });


  return (
    <div className='mt-2 p-4 bg-slate-50 dark:bg-slate-700 rounded-3xl flex flex-col text-center w-full'>
      <span className={`${isDark? 'text-gray-300':'text-gray-600'} text-xl`}>ARR</span>
        <HighchartsReact
            // highcharts={HighchartsStock}
            highcharts={Highcharts}
            // constructorType={'stockChart'}
            options={chartOptions}
        />
    </div>
  )
}

export default RevChurnStackedBarChart
