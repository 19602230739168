import React, { useEffect, useState } from 'react'
import { getData } from '../ApiCalls/DataApis';
import { apiLink } from '../ApiCalls/ApisVariables';
import EmailTable from './EmailTable';
import { useLocation, useSearchParams } from 'react-router-dom';


function EmailTrackingPage() {
    const [emailList, setEmailList] = useState([])
    let location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams()
    const [searchArray, setSearchArray] = useState([])

    useEffect(() => {
        // console.log('location', location)
        let qParameters = searchParams.getAll('q')
        let searcQuery = ''
        if(qParameters[0]){
            searcQuery = `?q=${qParameters[0]}`
            setSearchArray(qParameters)
        }
        // console.log('searcQuery', searcQuery)
        getData(apiLink + '/v1/internal/emails' + searcQuery)
        .then(async response => {
          if(response && response.ok ){
            const data = await response.json();
            if(data && data.data && data.data.length > 0 ){
                // console.log('Email List', data.data)
                setEmailList(data.data)
            }
          }
        });
  
      }, [location])
      

  return (
    <div className={`px-4 sm:px-1 sm:pl-6 sm:pr-3 py-1 max-h-[calc(100vh-5rem)] overflow-scroll scrollbar-hide`}> 
        <div className='w-full mt-2 p-2 overflow-scroll scrollbar-hide bg-slate-50 dark:bg-slate-800 rounded-xl  '>
            <EmailTable data={emailList} rowsPerPage={25} searchArray={searchArray} setSearchArray={setSearchArray} />
        </div>
    </div>
  )
}

export default EmailTrackingPage


