import { getData } from "../ApiCalls/DataApis"
import { apiLink } from "../ApiCalls/ApisVariables"
import { useEffect, useState } from "react";
import FeatureRequestTableRow from "./FeatureRequestTableRow";
import AddFeatureRequestModal from "../components/modals/AddFeatureRequestModal";

function FeatureRequestsComponent() {
  const [requestData, setRequestData] = useState([])

    useEffect(() => {
      getFeaturesList()
    }, [])

    const getFeaturesList = () => {
      getData(apiLink + '/v1/internal/dashboard/feature_requests')
      .then(async response => {
        if(response && response.ok ){
          const data = await response.json();
          if(data && data.data){
              // console.log('Requests',data.data)
              setRequestData(data.data)
          } 
        }
      });
    }

  

    
  return (
    <div className='w-full mt-2 mb-4 px-2 overflow-scroll scrollbar-hide bg-slate-50 dark:bg-slate-700 rounded-3xl max-h-[700px]'>
      <div>
        <h1 className='text-gray-600 dark:text-white text-xl text-center pt-2 pb-4 font-semibold'>Feature Requests</h1>
      </div>
      <div className='w-full felx justify-end'>
        <AddFeatureRequestModal getFeaturesList={getFeaturesList} />
      </div>
       <table className="table-auto w-full min-w-[800px] overflow-scroll">
        <thead >
          <tr className='border-b-[0.01em] dark:border-zinc-600 text-left'>
            <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 text-center'>Status</th>
            <th className=''></th>
            <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3'>Subject</th>
            <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3'>Description</th>
            <th className=''></th>
            <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 text-center'>Priority</th>
            <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 text-right'>Created At</th>
          </tr>
        </thead>
        <tbody >
        {requestData.map((request) => (
          <FeatureRequestTableRow key={request.feature_request_uuid} request={request} getFeaturesList={getFeaturesList} />
        ))}
        </tbody>
      </table>
    </div>
  )
}

export default FeatureRequestsComponent