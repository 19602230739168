import { useEffect } from 'react'
import { useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { motion } from "framer-motion"
import { useLocation, useSearchParams } from 'react-router-dom'
import { getData } from '../../ApiCalls/DataApis'
import { apiLink } from '../../ApiCalls/ApisVariables'


function LeadStageFilterBtn({leadsStages}) {
   const [selectedLeadStages, setSelectedLeadStages] = useState([])
   const [checkedLeadStagesState, setCheckedLeadStagesState] = useState([])
   const [searchParams, setSearchParams] = useSearchParams()
   const location = useLocation();




   useEffect(() => {
    // Gettting search parameters from URL
        let currentSearch = location.search
        if(currentSearch){
            // Pulling all parts search parameters into an array
            let searchLeadStages = searchParams.getAll('lead_stage_uuids')

            let updatedCheckedState = leadsStages.map((item, index) =>
                searchLeadStages.includes(item.lead_stage_uuid) ? true : false
            );

            let selectedSearchedLeadStages = [] 
            updatedCheckedState.map((checked,index) =>
                checked ? selectedSearchedLeadStages.push(leadsStages[index]) : null
            )
            setSelectedLeadStages(selectedSearchedLeadStages)
            setCheckedLeadStagesState(updatedCheckedState)
        }else {
            setCheckedLeadStagesState(new Array(leadsStages.length).fill(false))
        }

    }, [location, leadsStages])

    // Currently donb't need because I'm just using the dates of the array and don't need the entire json of dep info
//    useEffect(() => {
//     let checkedDep = []
//         if(checkedLeadStagesState.includes(true)){
//             leadsStages.map((dep, index) =>{
//                 if(checkedLeadStagesState[index]){
//                     checkedDep.push(dep)
//                 }
//             })
//         }
//         console.log('checkedDep', checkedDep)
//    }, [checkedLeadStagesState])


   const handleChangeLeadStageState = (position) => {
        let updatedCheckedState = checkedLeadStagesState.map((item, index) =>
        index === position ? !item : item
        );
        setCheckedLeadStagesState(updatedCheckedState)
        
    }

    const handleApplyFilter = () => {
        // putting all selected parts into the button for visualization
        let tempLeadStage = []
        checkedLeadStagesState.map((state, index) =>{
            if(state){
                tempLeadStage.push(leadsStages[index]) 
            }
        })
        setSelectedLeadStages(tempLeadStage)

        // putting each part ID into an array to push it to the URL
        let parameters = []
        tempLeadStage.map(dep => {
            parameters.push(dep.lead_stage_uuid)
        })

        let lead_tags = searchParams.getAll('lead_tags')
        let assignees = searchParams.getAll('assignees')
        let search = searchParams.getAll('search')
        
        setSearchParams({ assignees, lead_tags, lead_stage_uuids: parameters, search})
        
       
        
        

    }

  return (
    <div >
        <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`
                ${open ? 'bg-slate-100 dark:bg-slate-700' : ''}
                border border-blue-600 border-dashed rounded-full flex pl-1 pr-3 py-0.5 items-center outline-none max-w-[250px] `}
            >
                <div className='bg-white w-3 h-3 rounded-full m-1'>
                    <svg xmlns="http://www.w3.org/2000/svg" className={`transition ease-in-out duration-300 ${Object.keys(selectedLeadStages).length > 0? ' rotate-45 ':'rotate-0'} h-5 w-5 ml-[-4px] mt-[-4px] text-blue-600 `} viewBox="0 0 20 20" fill="currentColor" >
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
                    </svg>
                </div>
                <div className='pl-1 text-sm text-gray-600 dark:text-gray-300'>
                    Stages
                </div>
                {Object.keys(selectedLeadStages).length > 0 && Object.keys(selectedLeadStages).length < 3 &&
                <div className='flex items-center truncate'>
                    <div className='text-blue-600 pl-1 dark:text-blue-400 text-xs'>|</div>
                    {selectedLeadStages.map((filtered,index) => (
                        <div key={filtered.lead_stage_uuid} className='flex '>
                        {index > 0 && <div className='text-blue-600 dark:text-blue-400 text-xs'>,</div>}
                        <div className='text-blue-600 dark:text-blue-400 pl-0.5 text-xs '>{filtered.lead_stage_name}</div>
                        </div>
                    ))}
                    <div className='flex items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className='text-gray-400 w-4 h-4'>
                            <path fillRule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clipRule="evenodd" />
                        </svg>
                    </div>

                </div>
                }
                {Object.keys(selectedLeadStages).length >= 3 &&
                <div className='flex items-center truncate'>
                    <div className='text-blue-600 pl-1 dark:text-blue-400 text-xs'>|</div>
                    <div className='flex '>
                        <div className='text-blue-600 dark:text-blue-400 pl-0.5 text-xs '>{selectedLeadStages[0].lead_stage_name} and {Object.keys(selectedLeadStages).length - 1} more</div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className='text-gray-400 w-4 h-4'>
                        <path fillRule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clipRule="evenodd" />
                    </svg>
                </div>
                }
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-32 z-20 mt-3 w-[260px] -translate-x-1/2 transform ">
                <div className=" overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-slate-800 p-3">
                        <div className='text-sm font-bold text-gray-900 dark:text-gray-300'>Filter by Lead Stage</div>
                        {leadsStages.map((leadStage, index)=>(
                            <div key={`${leadStage.lead_stage_uuid}-${leadStage.lead_stage_name}`} className="flex items-center my-3">
                                <input id={`lead-stage-checkbox-${index}`} type="checkbox" checked={checkedLeadStagesState[index]} onChange={() => handleChangeLeadStageState(index)}  className="checkboxBtn"/>
                                <label htmlFor={`lead-stage-checkbox-${leadStage.lead_stage_uuid}`} className="ml-2 text-xs font-medium text-gray-600 dark:text-gray-400">{leadStage.lead_stage_name}</label>
                            </div>
                        ))}
                        

                        <motion.button  
                            whileHover={{ scale: 1.02 }} 
                            whileTap={{ scale: 0.98 }} 
                            onClick={ () => {
                                handleApplyFilter()
                                close()
                            }} 
                            className='w-full text-sm p-1 bg-blue-600 text-white rounded-md' >
                                Apply
                        </motion.button>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  )
}

export default LeadStageFilterBtn