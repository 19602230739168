import React from 'react'

function SessionLogsTable({sessionLogs}) {

    const formatDate = (currentDate) => {
        if(currentDate){
          var endDate = new Date(currentDate)
            return endDate.toLocaleDateString()
            // var endDate = new Date(currentDate)
            // // var endHours = endDate.getHours();
            // // var endMinutes = endDate.getMinutes();
            // // var endAmPm = endHours >= 12 ? 'pm' : 'am';
            // // endHours = endHours % 12;
            // // endHours = endHours ? endHours : 12; // the hour '0' should be '12'
            // // endMinutes = endMinutes < 10 ? '0'+endMinutes : endMinutes;
            // // var endStrTime = endHours + ':' + endMinutes + ' ' + endAmPm;
            // var shortEndDate = `${endDate.toLocaleString('default', { month: 'short' })} ${endDate.getDate()}, ${endDate.getFullYear().toString()}`
            // // var shortEndDate = endDate.getMonth()+1 + "" + endDate.getDate() + "," + endDate.getFullYear().toString().substr(-2)
            // // var endTime = endDate.getMonth()+1 + "/" + endDate.getDate() + "/" + endDate.getFullYear().toString().substr(-2) + " " + endStrTime;
            // // var startTime = startDate.getMonth()+1 + "/" + startDate.getDate() + "/" + startDate.getFullYear().toString().substr(-2) + " " + startStrTime;
            // return shortEndDate ;
            // // return shortEndDate + "  " + endStrTime;
        }
    }


  return (
    <div className='w-full mt-2 px-2 overflow-scroll scrollbar-hide bg-slate-50 dark:bg-slate-700 rounded-3xl lg:h-[550px]'>
    <table className="table-auto w-full ">
        <thead >
          <tr className='border-b-[0.01em] dark:border-zinc-600 text-left'>
            <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 min-w-[150px]'>Date</th>
            <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3'>Organizations</th>
            <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3'>Users</th>
            {/* <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 text-right'>{`Total Spent`}</th> */}
          </tr>
        </thead>
        <tbody >
        {sessionLogs.map((session, index) => (
          <tr key={index} className='border-b-[0.01em]  dark:border-zinc-500 '>
            <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3'>{formatDate(session.user_log_timestamp)}</td>
            <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3'>
                <div className='flex flex-wrap'>
                    {session.unique_organizations.map((org, index) => (
                        <span key={`${org}-${index}`} className='px-2 py-0.5 bg-blue-200 rounded-full m-1 text-gray-600'>{org}</span>
                    ))}
                </div>
            </td>
            <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3 text-right'>
                <div className='flex flex-wrap'>
                    {session.unique_users.map((user, index) => (
                        <span key={`${user}-${index}`} className='px-2 py-0.5 bg-rose-200 rounded-full m-1 text-gray-600'>{user}</span>
                    ))}
                </div>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
  </div>
  )
}

export default SessionLogsTable