// import HighchartsStock from 'highcharts/highstock';
// import highchartsMore from "highcharts/highcharts-more.js";
import HighchartsReact from "highcharts-react-official";
import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { darkModeState } from '../atoms/darkModeState';
import Highcharts from 'highcharts'
import drilldown from "highcharts/modules/drilldown.js";

drilldown(Highcharts);

function CustomerSenseAiBarChart({customerData}) {
    const [chartOptions, setChartOptions] = useState({})
    const isDark = useRecoilValue(darkModeState);

    useEffect(() => {
        if(customerData){
            let chartData = []
            customerData.map(customer => {
                chartData.push({name:customer.organization_name , y:Number(customer.senseai_count)})
            })
            setChartOptions({

                chart: {
                    type: 'column',
                    backgroundColor: 'transparent',
                    height:'550px'
                    // events: {
                    //     drilldown: function(e) {
                    //       // console.log("Drilldown" + e.point.name);
                    //       if (!e.seriesOptions) {
                    //         var chart = this;
                    //         if (e.point.name === "Parker") {
                    //           chart.addSingleSeriesAsDrilldown(e.point, {
                    //             name: "New",
                    //             color: "green",
                    //             data: [["Mary", 34], ["Peter", 22]]
                    //           });
                    //           chart.addSingleSeriesAsDrilldown(e.point, {
                    //             name: "In Progress",
                    //             color: "blue",
                    //             data: [["Mary", 4], ["Peter", 12]]
                    //           });
                    //         }
                    //         chart.applyDrilldown();
                    //       }
                    //     }
                    // }
                },
                title: {
                    text: ''
                },
                credits : { enabled : false},
                accessibility: {
                    announceNewData: {
                        enabled: true
                    }
                },
                xAxis: {
                    type: 'category',
                    labels: {
                        style: {
                            color: isDark ? '#d1d5db':'#4b5563'
                        }
                    }
                },
                yAxis: {
                    title: {
                        text: ''
                    },
                    labels: {
                        style: {
                          color: isDark ? '#d1d5db':'#4b5563'
                        }
                    },
                    lineWidth: 0,
                    minorGridLineWidth: 0,
                    // lineColor: isDark ? '#f9fafb':'#dc2626',
                    // labels: {
                    //     enabled: false
                    // },
                    // min:0,
                    minorTickLength: 0,
                    tickLength: 0,
                    gridLineWidth: 0.3,
                    gridLineColor: isDark ? '#a1a1aa':'#a1a1aa',

                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                            style: {
                              fontWeight: 500,
                              color: isDark ? '#d1d5db':'#4b5563',
                              fontSize:14,
                              format: '{point.y:.0f}'
                          }
                        },
                    }
                },

                tooltip: {
                    headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                    pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><br/>'
                },

                series: [
                    {
                        name: 'Customer',
                        // colorByPoint: true,
                        data: chartData,
                        // data: [
                        //     {
                        //         name: 'Parker',
                        //         y: 12,
                        //         // drilldown: 'Parker'
                        //     },
                        //     {
                        //         name: 'Lincotek',
                        //         y: 10,
                        //         // drilldown: 'Lincotek'
                        //     },
                        //     {
                        //         name: 'Delta',
                        //         y: 10,
                        //         // drilldown: 'Delta'
                        //     },
                        //     {
                        //         name: 'SendCutSend',
                        //         y: 8,
                        //         // drilldown: 'SendCutSend'
                        //     },
                        //     {
                        //         name: 'Hugo Benzing',
                        //         y: 10,
                        //         // drilldown: 'Hugo Benzing'
                        //     },
                        //     {
                        //         name: 'OMADA Int-Sumner',
                        //         y: 6,
                        //         // drilldown: 'OMADA Int-Sumner'
                        //     },
                        // ]
                    }
                ],
                // drilldown: {
                //     breadcrumbs: {
                //         position: {
                //             align: 'right'
                //         }
                //     },
                //     activeAxisLabelStyle: {
                //         color: isDark ? '#d1d5db':'#4b5563'
                //     },
                //     activeDataLabelStyle: {
                //         color: isDark ? '#d1d5db':'#4b5563'
                //     },
                //     series: [
                //         {
                //             name: 'Parker',
                //             id: 'Parker',
                //             data: [
                //                 [
                //                     'v65.0',
                //                     0.1
                //                 ],
                //                 [
                //                     'v64.0',
                //                     1.3
                //                 ],
                //                 [
                //                     'v63.0',
                //                     53.02
                //                 ],
                //                 [
                //                     'v62.0',
                //                     1.4
                //                 ],
                //                 [
                //                     'v61.0',
                //                     0.88
                //                 ],
                //                 [
                //                     'v60.0',
                //                     0.56
                //                 ],
                //                 [
                //                     'v59.0',
                //                     0.45
                //                 ],
                //                 [
                //                     'v58.0',
                //                     0.49
                //                 ],
                //                 [
                //                     'v57.0',
                //                     0.32
                //                 ],
                //                 [
                //                     'v56.0',
                //                     0.29
                //                 ],
                //                 [
                //                     'v55.0',
                //                     0.79
                //                 ],
                //                 [
                //                     'v54.0',
                //                     0.18
                //                 ],
                //                 [
                //                     'v51.0',
                //                     0.13
                //                 ],
                //                 [
                //                     'v49.0',
                //                     2.16
                //                 ],
                //                 [
                //                     'v48.0',
                //                     0.13
                //                 ],
                //                 [
                //                     'v47.0',
                //                     0.11
                //                 ],
                //                 [
                //                     'v43.0',
                //                     0.17
                //                 ],
                //                 [
                //                     'v29.0',
                //                     0.26
                //                 ]
                //             ]
                //         },
                //         {
                //             name: 'Delta',
                //             id: 'Delta',
                //             data: [
                //                 [
                //                     'v58.0',
                //                     1.02
                //                 ],
                //                 [
                //                     'v57.0',
                //                     7.36
                //                 ],
                //                 [
                //                     'v56.0',
                //                     0.35
                //                 ],
                //                 [
                //                     'v55.0',
                //                     0.11
                //                 ],
                //                 [
                //                     'v54.0',
                //                     0.1
                //                 ],
                //                 [
                //                     'v52.0',
                //                     0.95
                //                 ],
                //                 [
                //                     'v51.0',
                //                     0.15
                //                 ],
                //                 [
                //                     'v50.0',
                //                     0.1
                //                 ],
                //                 [
                //                     'v48.0',
                //                     0.31
                //                 ],
                //                 [
                //                     'v47.0',
                //                     0.12
                //                 ]
                //             ]
                //         },
                //         {
                //             name: 'OMADA Int-Sumner',
                //             id: 'OMADA Int-Sumner',
                //             data: [
                //                 [
                //                     'v11.0',
                //                     6.2
                //                 ],
                //                 [
                //                     'v10.0',
                //                     0.29
                //                 ],
                //                 [
                //                     'v9.0',
                //                     0.27
                //                 ],
                //                 [
                //                     'v8.0',
                //                     0.47
                //                 ]
                //             ]
                //         },
                //         {
                //             name: 'Lincotek',
                //             id: 'Lincotek',
                //             data: [
                //                 [
                //                     'v11.0',
                //                     3.39
                //                 ],
                //                 [
                //                     'v10.1',
                //                     0.96
                //                 ],
                //                 [
                //                     'v10.0',
                //                     0.36
                //                 ],
                //                 [
                //                     'v9.1',
                //                     0.54
                //                 ],
                //                 [
                //                     'v9.0',
                //                     0.13
                //                 ],
                //                 [
                //                     'v5.1',
                //                     0.2
                //                 ]
                //             ]
                //         },
                //         {
                //             name: 'SendCutSend',
                //             id: 'SendCutSend',
                //             data: [
                //                 [
                //                     'v16',
                //                     2.6
                //                 ],
                //                 [
                //                     'v15',
                //                     0.92
                //                 ],
                //                 [
                //                     'v14',
                //                     0.4
                //                 ],
                //                 [
                //                     'v13',
                //                     0.1
                //                 ]
                //             ]
                //         },
                //         {
                //             name: 'Hugo Benzing',
                //             id: 'Hugo Benzing',
                //             data: [
                //                 [
                //                     'v50.0',
                //                     0.96
                //                 ],
                //                 [
                //                     'v49.0',
                //                     0.82
                //                 ],
                //                 [
                //                     'v12.1',
                //                     0.14
                //                 ]
                //             ]
                //         }
                //     ]
                // }
            })
        }

    }, [customerData, isDark])

  return (
    <div className='mt-2 p-4 bg-slate-50 dark:bg-slate-700 rounded-3xl flex flex-col text-center w-full h-[550px] 2xl:h-[600px]'>
      <span className={`${isDark? 'text-gray-300':'text-gray-600'} text-xl`}>Devices per Customer</span>
        <HighchartsReact
            // highcharts={HighchartsStock}
            highcharts={Highcharts}
            // constructorType={'stockChart'}
            options={chartOptions}
        />
    </div>
  )
}

export default CustomerSenseAiBarChart