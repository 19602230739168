import React, { useRef, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
// import mapboxgl from 'mapbox-gl'; // assuming Mapbox GL JS is installed with npm install mapbox-gl
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

// Replace this with your Mapbox access token
const accessToken = 'pk.eyJ1IjoiaW90Zmxvd3MiLCJhIjoiY2tzMmk2anFuMG9waTJ5bzRoN2FkbHJmNSJ9.OqgJVydjwZerowqBY-K2lA';

mapboxgl.accessToken = accessToken;

const Marker = ({ onClick, children, feature }) => {
    const _onClick = () => {
      onClick(feature);
    };
  
    
    return (
        <button onClick={_onClick} className="relative flex h-4 w-4">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-500 opacity-75"></span>
            <span className="relative inline-flex rounded-full h-4 w-4 bg-green-500"></span>            
            {/* {children} */}
        </button>
    );
  };



function Map({customerData}) {
    const mapContainerRef = useRef(null);
    const [activePopup, setActivePopup] = useState(null);

    // Initialize map when component mounts
    useEffect(() => {
        if(customerData && Object.keys(customerData).length > 0){
            setTimeout(() => {
                const map = new mapboxgl.Map({
                  container: mapContainerRef.current,
                  style: 'mapbox://styles/iotflows/cks2ifj255lqk17p2i5nwheqy',
                  // style: 'mapbox://styles/mapbox/streets-v11',
                  center: [-91, 35],
                  zoom: 3,
                });

                // remove logo
                let elements = document.getElementsByClassName('mapboxgl-ctrl-attrib')    
                if(elements && elements.length) elements[0].parentNode.removeChild(elements[0]);
                
                customerData.forEach((feature) => {
                    // Create a React ref
                    const ref = React.createRef();
                    // Create a new DOM node and save it to the React ref
                    ref.current = document.createElement('div');
                    // Render a Marker Component on our new DOM node
                    createRoot(ref.current).render(
                        <Marker onClick={markerClicked} feature={feature} />
                    );
    
                
                    
                    // // Create a Mapbox Marker at our new DOM node
                    new mapboxgl.Marker(ref.current)
                    .setLngLat(feature.coordinates)
                    .addTo(map);
                })
            
                // Add navigation control (the +/- zoom buttons)
                map.addControl(new mapboxgl.NavigationControl(), 'top-right');

                
            
                // Clean up on unmount
                return () => map.remove();
              }, 500);

        }
          
    }, [customerData]);
  
    // const markerClicked = (title) => {
    // //   window.alert(title);
    //   <div className='text-gray-500'>
    //     {title}
    //   </div>
    // };
    const markerClicked = (customer) => {
    //   window.alert(title);
    console.log('customerInfo', customer)
        setActivePopup(customer)
    };
      

  return (
    <div className='relative rounded-xl h-[400px] 2xl:h-[600px]'  style={{ width: '100%' }}>
        <div className="absolute top-0 bottom-0 left-0 right-0 rounded-3xl lg:mr-2" ref={mapContainerRef}>
        {activePopup && (
            <div className="bg-blue-500 text-white rounded-xl px-4 py-2 absolute top-1 left-1 font-medium">
                <div dangerouslySetInnerHTML={{ __html: `${activePopup.organization_name}` }} />
                <div dangerouslySetInnerHTML={{ __html: `${activePopup.location}` }} />
                <div dangerouslySetInnerHTML={{ __html: `SenseAi: ${activePopup.senseai_count}` }} />
            </div>
        )}
        </div>
    </div>
  );
}

export default Map;

