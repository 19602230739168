import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

function CustomerTable({customerData}) {
    const navigate = useNavigate()
    const [filteredTableData, setFilteredTableData] = useState([])
    const [isSenseAiCountHigh, setIsSenseAiCountHigh] = useState(true)
    const [isArrHigh, setIsArrCountHigh] = useState(true)
    const [isTotalSpendHigh, setIsTotalSpendHigh] = useState(true)
    const [isCurrentDate, setIsCurrentDate] = useState(true)
    const [mainSearchText, setMainSearchText] = useState('')

    useEffect(() => {
      if(customerData){
        setFilteredTableData(customerData)
      }

    }, [customerData])

    const formatDate = (date) => {
        if(date){
            const options = {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
              };
              
              const formatter = new Intl.DateTimeFormat('en-US', options); // Adjust locale if needed
              const formattedDate = formatter.format(new Date(date));

              return formattedDate
        }
    }

    
    const sortByHighestValueSenseAiCount = () => {

      if(isSenseAiCountHigh){
        setIsSenseAiCountHigh(false)
        let temp = [...customerData]
        // const sortedData = customerData.slice().sort((a, b) => b[parameter] - a[parameter])
        temp.sort((a, b) => {
          return a.senseai_count - b.senseai_count
        })
        setFilteredTableData(temp)
      }else{
        setIsSenseAiCountHigh(true)
        let temp = [...customerData]
        // const sortedData = customerData.slice().sort((a, b) => b[parameter] - a[parameter])
        temp.sort((a, b) => {
          return  b.senseai_count - a.senseai_count
        })
        setFilteredTableData(temp)

      }
    }

    const sortByHighestValueARR = () => {

      if(isArrHigh){
        setIsArrCountHigh(false)
        let temp = [...customerData]
        temp.sort((a, b) => {
          return a.actual_arr - b.actual_arr
        })
        setFilteredTableData(temp)

      }else{
        setIsArrCountHigh(true)
        let temp = [...customerData]
        temp.sort((a, b) => {
          return  b.actual_arr - a.actual_arr
        })
        setFilteredTableData(temp)

      }
    }

    const sortByHighestValueTotalSpend = () => {

      if(isTotalSpendHigh){
        setIsTotalSpendHigh(false)
        let temp = [...customerData]
        temp.sort((a, b) => {
          return a.organization_total_amount_paid - b.organization_total_amount_paid
        })
        setFilteredTableData(temp)

      }else{
        setIsTotalSpendHigh(true)
        let temp = [...customerData]
        temp.sort((a, b) => {
          return  b.organization_total_amount_paid - a.organization_total_amount_paid
        })
        setFilteredTableData(temp)

      }
    }

    const sortByDate = () => {

      if(isCurrentDate){
        setIsCurrentDate(false)
        let temp = [...customerData]
        temp.sort((a, b) => {
          let dateA = new Date(a.created_at)
          let dateB = new Date(b.created_at)
          return dateA - dateB
        })
        setFilteredTableData(temp)

      }else{
        setIsCurrentDate(true)
        let temp = [...customerData]
        temp.sort((a, b) => {
          let dateA = new Date(a.created_at)
          let dateB = new Date(b.created_at)
          return  dateB - dateA
        })
        setFilteredTableData(temp)

      }
    }

    

    const handleSearchFilter = (e) => {
      let parameters = e.target.value
      setMainSearchText(parameters)
      
      const filteredCustomers = customerData.filter((customer) => {
          const name = customer.organization_name.toLowerCase(); // Case-insensitive search
          const searchTerm = parameters.toLowerCase();
          return name.includes(searchTerm)

      })
      setFilteredTableData(filteredCustomers)
      
    }

    let USDollar = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
  });


  return (
    <div className='w-full mt-2 px-2 overflow-scroll scrollbar-hide bg-slate-50 dark:bg-slate-700 rounded-3xl h-[600px] 2xl:h-[600px]'>
    <div className='relative rounded-full mt-1 px-2 py-2 w-full'>
        <div className='absolute inset-y-0 pl-3 flex items-center pointer-events-none'>
            <svg className={`h-4 w-4 dark:text-gray-300 text-gray-500`}  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
            </svg>
        </div>
        <input className={`dark:hover:bg-gray-900 dark:focus:bg-gray-900 dark:bg-gray-800 dark:focus:ring-gray-500 dark:text-gray-300 dark:placeholder:text-gray-400 bg-gray-100 text-gray-600 border-gray-300 focus:ring-gray-300 focus:border-gray-300 placeholder:text-gray-500
                        block w-full pl-10 sm:text-xs rounded-full text-ellipsis py-1 `}
            value={mainSearchText} 
            onChange={handleSearchFilter} 
            type="text" 
            placeholder="Search Customer..."
            
        />
    </div>
    <table className="table-auto w-full ">
        <thead >
          <tr className='border-b-[0.01em] dark:border-zinc-600 text-left'>
            <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3'>Customer</th>
            <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 cursor-pointer text-right' onClick={sortByHighestValueSenseAiCount} >
              <div className='flex justify-end items-center'>
                <span>SenseAi Count</span>
                <span className='pl-1'>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-4 h-4 transition duration-300 ease-in-out ${isSenseAiCountHigh ? 'rotate-180':'rotate-0'}`}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3" />
                  </svg>
                </span>
              </div>
            </th>
            <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 text-right'>Router Count</th>
            <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 cursor-pointer text-right' onClick={sortByHighestValueARR}>
              <div className='flex justify-end items-center'>
                <span>Actual ARR</span>
                <span className='pl-1'>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-4 h-4 transition duration-300 ease-in-out ${isArrHigh ? 'rotate-180':'rotate-0'}`}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3" />
                  </svg>
                </span>
              </div>
            </th>
            <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 text-right'>{`ARR`}</th>
            <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 cursor-pointer text-right' onClick={sortByHighestValueTotalSpend}>
              <div className='flex justify-end items-center'>
                <span>Total Spend</span>
                <span className='pl-1'>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-4 h-4 transition duration-300 ease-in-out ${isTotalSpendHigh ? 'rotate-180':'rotate-0'}`}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3" />
                  </svg>
                </span>
              </div>
            </th>
            <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 text-right'>{`Location`}</th>
            <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 cursor-pointer text-right' onClick={sortByDate}>
              <div className='flex justify-end items-center'>
                <span>Lifespan</span>
                <span className='pl-1'>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-4 h-4 transition duration-300 ease-in-out ${isCurrentDate ? 'rotate-180':'rotate-0'}`}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3" />
                  </svg>
                </span>
              </div>
            </th>
            <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 cursor-pointer text-right' onClick={sortByDate}>
              <div className='flex justify-end items-center'>
                <span>Created At</span>
                <span className='pl-1'>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-4 h-4 transition duration-300 ease-in-out ${isCurrentDate ? 'rotate-180':'rotate-0'}`}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3" />
                  </svg>
                </span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody >
        {filteredTableData.map((customer, index) => (
          <tr key={customer.organization_uuid} className='border-b-[0.01em]  dark:border-zinc-500 cursor-pointer hover:bg-slate-100 dark:hover:bg-slate-800' onClick={() => navigate(`/customer/selected-customer/${customer.organization_uuid}`)}>
            <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3'>{customer.organization_name}</td>
            <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3 text-right'>{customer.senseai_count}</td>
            <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3 text-right'>{`NA`}</td>
            {/* <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3 text-right'>{customer.lifetime}</td> */}
            <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3 text-right'>{USDollar.format(customer.actual_arr)}</td>
            <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3 text-right'>{USDollar.format(customer.arr)}</td>
            <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3 text-right'>{USDollar.format(customer.organization_total_amount_paid)}</td>
            <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3 text-right'>{customer.location}</td>
            <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3 text-right'>{customer.lifespan}</td>
            <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3 text-right'>{formatDate(customer.created_at)}</td>
          </tr>
        ))}
        </tbody>
      </table>
  </div>
  )
}

export default CustomerTable