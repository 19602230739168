// import HighchartsStock from 'highcharts/highstock';
// import highchartsMore from "highcharts/highcharts-more.js";
import HighchartsReact from "highcharts-react-official";
import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { darkModeState } from '../atoms/darkModeState';
import Highcharts from 'highcharts'


// highchartsMore(HighchartsStock);

function CashARRBurnChart({chartDates, senseAiMonthlyCount}) {
    const [chartOptions, setChartOptions] = useState({
      chart: {
        backgroundColor: 'transparent',
        // height: '300px'
      },
      title: {
        text: ''
        },
      yAxis: {
          title: {
              text: ''
          }
      },
      credits : { enabled : false},
    })
    const isDark = useRecoilValue(darkModeState);

    

    useEffect(() => {
        if(senseAiMonthlyCount && Object.keys(senseAiMonthlyCount).length > 0){
            let mrr = []
            senseAiMonthlyCount.map(devices => {
                // mrr.push(Number(devices.senseai_assigned_count) * 83 * 12)
                mrr.push(Number(devices.arr)) // calculated from the backend
            })
            setChartOptions({
              chart: {
                  backgroundColor: 'transparent',
                  // height: '300px'
              },
              title: {
                text: ''
                },
              yAxis: {
                  title: {
                      text: ''
                  }
              },

              legend: {
                  layout: 'horizontal',
                  align: 'center',
                  verticalAlign: 'bottom',
                  itemStyle: {
                    fontSize:'14px',
                    color: isDark ? '#d1d5db':'#4b5563'
                 },

              },
              tooltip: {
                shared: true,
                // headerFormat: '<b>Hunting season starting autumn {point.x}</b><br>'
              },
              plotOptions: {
                  series: {
                      label: {
                          connectorAllowed: false
                      },
                      lineWidth: 2
                  },
                  line: {
                    dataLabels: {
                        enabled: true,
                        style: {
                          fontWeight: 500,
                          color: isDark ? '#d1d5db':'#4b5563',
                          fontSize:14
                      },
                        formatter: function () {
                            return formatMoney.format(this.y);
                        }
                    },
                }
              },
              xAxis: {
                categories: chartDates,
                labels: {
                    style: {
                        color: isDark ? '#d1d5db':'#4b5563'
                    }
                }
              },
              yAxis:[{ // Primary yAxis
                labels: {
                  style: {
                    color: isDark ? '#d1d5db':'#4b5563'
                  }
                },
                plotLines: [{
                    value: 0,
                    width: 2,
                    color: 'silver'
                }],
                title: {
                    text: '',
                  },
                lineWidth: 0,
                minorGridLineWidth: 0,
                lineColor: 'transparent',
                // labels: {
                //     enabled: false
                // },
                // min:0,
                minorTickLength: 0,
                tickLength: 0,
                gridLineWidth: 0,
                opposite: true
        
              }, { // Secondary yAxis
                labels: {
                  style: {
                    color: isDark ? '#d1d5db':'#4b5563'
                  }
                },
                plotLines: [{
                    value: 0,
                    width: 2,
                    color: 'silver'
                }],
                title: {
                    text: '',
                  },
                lineWidth: 0,
                minorGridLineWidth: 0,
                lineColor: 'transparent',
                // labels: {
                //     enabled: false
                // },
                // min:0,
                minorTickLength: 0,
                tickLength: 0,
                gridLineWidth: 0,
              }],
              credits : { enabled : false},

              series: [{
                  name: 'Revenue',
                  color: '#0ea5e9',
                  yAxis: 1,
                  data: mrr
              }
            ],

              responsive: {
                  rules: [{
                      condition: {
                          maxWidth: 500
                      },
                      chartOptions: {
                          legend: {
                              layout: 'horizontal',
                              align: 'center',
                              verticalAlign: 'bottom'
                          }
                      }
                  }]
              }
            })
        }

    }, [senseAiMonthlyCount, isDark])

    const formatMoney = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
      
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

  return (
    <div className='mt-2 p-4 bg-slate-50 dark:bg-slate-700 rounded-3xl flex flex-col text-center w-full'>
      <span className={`${isDark? 'text-gray-300':'text-gray-600'} text-xl`}>ARR</span>
        <HighchartsReact
            // highcharts={HighchartsStock}
            highcharts={Highcharts}
            // constructorType={'stockChart'}
            options={chartOptions}
        />
    </div>
  )
}

export default CashARRBurnChart
