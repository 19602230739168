import { useState } from 'react'
import { useRecoilValue } from 'recoil';
import {darkModeState} from '../../atoms/darkModeState'
import { PencilIcon, TrashIcon } from '@heroicons/react/solid';

function TagListForInput({tag, handleAddTagFromList}) {
    const isDark = useRecoilValue(darkModeState);
    const [editTag, setEditTag] = useState(false)
    const [deleteTag, setDeleteTag] = useState(false)
    
  return (
    <div className={` w-full p-1 rounded flex items-center`}  >

        {!editTag && !deleteTag && 
            <>
                <div className={`h-6 w-fit inline-block flex text-white ${isDark?' bg-slate-900':'bg-slate-500'} text-xs cursor-pointer rounded-md items-center justify-center ml-1 px-2`} onClick={() => handleAddTagFromList(tag.tag)}>
                    <span >{tag.tag}</span>
                </div>
                {/* <button className={`${isDark?'text-blue-400 hover:text-blue-200':'text-blue-600 hover:text-blue-900'} cursor-pointer flex ml-auto`} onClick={() => setEditTag(true)}>
                    <PencilIcon className={`w-4 h-4`}/>
                </button>
                <button className={`${isDark?'text-blue-400 hover:text-blue-200':'text-blue-600 hover:text-blue-900'} cursor-pointer flex ml-2`} onClick={() => setDeleteTag(true)}>
                    <TrashIcon className={`w-4 h-4`}/>
                </button>  */}
            </>
        }

        {editTag && !deleteTag && 
            <div className='h-6 flex '>
                <input 
                    className={`w-fit block bg-transparent border rounded-md py-0.5 px-3 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 text-sm ${isDark?'placeholder:text-gray-500 text-gray-300 border-gray-600 focus:border-blue-800 focus:ring-blue-800':'placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300'}`} 
                    id={tag} 
                    type="text" 
                    defaultValue={tag}
                />
                <div className={`shadow rounded-md py-1 px-2 border-[1px] text-xs ${isDark?'text-white hover:text-gray-200 border-gray-400':'text-gray-600 hover:text-gray-900 border-gray-300'} cursor-pointer flex ml-2`} onClick={() => setEditTag(false)}>
                    Cancel
                </div>
                <div className={`shadow rounded-md ml-1 py-1 px-2 border-[1px] border-blue-600 text-xs bg-blue-600 hover:bg-blue-700 hover:border-blue-700 text-white cursor-pointer`} onClick={() => setEditTag(false)}>
                    Save
                </div>

            </div>
        }

        {!editTag && deleteTag && 
            <div className='flex ml-2 items-center'>
                <span className={`text-sm text-left font-medium ${isDark?'text-gray-300':'text-gray-600'}`}>Are you sure you want to delete {tag} tag?</span>
                <div className={`shadow rounded-md py-1 px-2 border-[1px] text-xs ${isDark?'text-white hover:text-gray-200 border-gray-400':'text-gray-600 hover:text-gray-900 border-gray-300'} cursor-pointer flex ml-2`} onClick={() => setDeleteTag(false)}>
                    Cancel
                </div>
                <div className={`shadow rounded-md ml-1 py-1 px-2 border-[1px] border-red-600 text-xs bg-red-600 hover:bg-red-700 hover:border-red-700 text-white cursor-pointer`} onClick={() => setDeleteTag(false)}>
                    Delete
                </div>
            </div>
        }
        
        
    </div>
  )
}

export default TagListForInput