import React, { useEffect, useState } from 'react'

function SummaryKpisComponent({mainMetrics, currentOnlineDevices, customerCount}) {
    const [cashOnHand, setCashOnHand] = useState(387000)
    const [burnRate, setBurnRate] = useState(365600)
    const [runway, setRunway] = useState(0)
    const [cashFlow, setCashFlow] = useState(0)

    useEffect(() => {
        if(mainMetrics){
            let arr = mainMetrics.actual_arr
            let burn = mainMetrics.burn_rate

            let cash_flow = arr - burn
            setCashFlow(cash_flow)
        }

    }, [mainMetrics])

    const formatMoney = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
      
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

  
      const formatDate = (deadDate) => {
        if(deadDate){
            // Create a Date object from the string
        const date = new Date(deadDate);

        // Get individual components (year, month, day)
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // Months are zero-indexed in JS
        const shortMonth = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);

        const day = date.getDate();

        // Format the date string (customize formatting as needed)
        const formattedDate = `${shortMonth} ${day}, ${year}`;

        return formattedDate;
        }

      }
    
  return (
    <div className='flex flex-col w-full bg-slate-50 dark:bg-slate-700 rounded-3xl px-4 py-6 mt-2'>
        <div className='grid grid-cols-2 sm:grid-cols-3 gap-2 md:grid-cols-4 xl:grid-cols-5 justify-around mt-1'>
            <div className='flex flex-col text-center'>
                <div className='text-gray-500 dark:text-gray-400 text-base lg:text-lg font-medium'>ARR</div>
                <div className='text-green-600 dark:text-green-500 text-2xl lg:text-3xl font-medium '>{mainMetrics.actual_arr ? formatMoney.format(mainMetrics.actual_arr): ''}</div>
            </div>
            <div className='flex flex-col text-center'>
                <div className='text-gray-500 dark:text-gray-400 text-base lg:text-lg font-medium'>ARR In Trial</div>
                <div className='text-amber-600 dark:text-amber-500 text-2xl lg:text-3xl font-medium '>{mainMetrics.actual_in_trial_arr ? formatMoney.format(mainMetrics.actual_in_trial_arr): ''}</div>
            </div>
            <div className='flex flex-col text-center'>
                <div className='text-gray-500 dark:text-gray-400 text-base lg:text-lg font-medium'>ARR Monthly</div>
                <div className='text-emerald-600 dark:text-emerald-500 text-2xl lg:text-3xl font-medium '>{mainMetrics.actual_monthly_arr ? formatMoney.format(mainMetrics.actual_monthly_arr) : ''}</div>
            </div>
            <div className='flex flex-col text-center'>
                <div className='text-gray-500 dark:text-gray-400 text-base lg:text-lg font-medium'>ARR Annual</div>
                <div className='text-emerald-600 dark:text-emerald-500 text-2xl lg:text-3xl font-medium '>{mainMetrics.actual_annual_arr ? formatMoney.format(mainMetrics.actual_annual_arr) : ''}</div>
            </div>
            <div className='flex flex-col text-center'>
                <div className='text-gray-500 dark:text-gray-400 text-base lg:text-lg font-medium'>Cash</div>
                <div className='text-green-600 dark:text-green-500 text-2xl lg:text-3xl font-medium '>{mainMetrics.cash ? formatMoney.format(mainMetrics.cash) : ''}</div>
            </div>
            <div className='flex flex-col text-center'>
                <div className='text-gray-500 dark:text-gray-400 text-base lg:text-lg font-medium'>Burn Rate</div>
                <div className='text-red-600 dark:text-red-500 text-2xl lg:text-3xl font-medium '>{mainMetrics.burn_rate ? formatMoney.format(mainMetrics.burn_rate) : ''}</div>
            </div>
            <div className='flex flex-col text-center'>
                <div className='text-gray-500 dark:text-gray-400 text-base lg:text-lg font-medium'>Cash Flow</div>
                <div className={`${cashFlow >= 0 ? 'text-green-600 dark:text-green-500':'text-red-600 dark:text-red-500'} text-2xl lg:text-3xl font-medium`}>{formatMoney.format(cashFlow)}</div>
            </div>
            <div className='flex flex-col text-center'>
                <div className='text-gray-500 dark:text-gray-400 text-base lg:text-lg font-medium'>Online Devices</div>
                <div className='text-gray-600 dark:text-gray-200 text-2xl lg:text-3xl font-medium '>{currentOnlineDevices}</div>
            </div>
            <div className='flex flex-col text-center'>
                <div className='text-gray-500 dark:text-gray-400 text-base lg:text-lg font-medium'>Customers</div>
                <div className='text-gray-600 dark:text-gray-200 text-2xl lg:text-3xl font-medium '>{customerCount}</div>
            </div>
            <div className='flex flex-col text-center'>
                <div className='text-gray-500 dark:text-gray-400 text-base lg:text-lg font-medium'>Runway</div>
                <div className='text-gray-600 dark:text-gray-200 text-2xl lg:text-3xl font-medium '>{mainMetrics.runway_months} <span className='text-xl lg:text-2xl'>m</span></div>
                <div className='text-gray-500 dark:text-gray-400 text-sm font-light '>{formatDate(mainMetrics.dead_timestamp)}</div>
            </div>

        </div>
    </div>
  )
}

export default SummaryKpisComponent