import Switch from '@mui/material/Switch';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import {darkModeState} from '../atoms/darkModeState'
import ProfileDropdown from './ProfileDropdown';
import MemberRoleButton from './MemberRoleButton';
import { getData, putData } from '../ApiCalls/DataApis';
import { apiLink } from '../ApiCalls/ApisVariables';
import IoTFlowsLogoWhiteFont from "../images/IoTFlowsLogoWhiteFont.png";
import IoTFlowsLogoBlackFont from "../images/IoTFlowsLogoBlackFont.png";
import { Link } from "react-router-dom";



function Header({user}) {
    const [isDark, setIsDark] = useRecoilState(darkModeState)
    const [showGODview, setShowGODview] = useState(false)


    useEffect(() => {
      // Determining if user had GOD view access
      getData(apiLink + '/v1/users/iotflows')
      .then(async response => {
        if(response && response.ok ){
          const data = await response.json();
          if(data && data.data.length > 0 && data.data[0].iotflows_member_role_id){
            setShowGODview(true)
          } 
        }
      });

    }, [])

  

    const handleChangeDarkMode = (e) => {
      setIsDark(e.target.checked)
      putData(apiLink + '/v1/users/darkmode', 
        {'user_prefers_dark_mode': e.target.checked})
          .then(async response => {
            const data = await response.json();
            if(response.ok){
              // setLoading(false)
              // getPartList()
              // toast.success('Part has been edited')
              // closeModal()
            }
            else{
              try{
                const data = await response.json();
                // toast.error(data.message)
              }catch (e){
                // toast.error('Something went wrong')
              }
            }
          })

    }


    
    return (
      <>
        <div className={`shadow-sm border-b dark:border-slate-700 bg-slate-100 dark:bg-slate-900 sticky top-0 z-50 pl-4 sm:pl-8 py-2`}>
          <header className="flex items-center py-0.5">
            {isDark ? (
              <div className='px-2 py-1'>
                <Link to={'/'}>
                  <img src={IoTFlowsLogoWhiteFont} className='w-24' alt='IoTFlows' />
                  </Link>
                
              </div>
            ):(
              <div className='px-2 py-1'>
                <Link to={'/'}>
                  <img src={IoTFlowsLogoBlackFont} className='w-24' alt='IoTFlows' />
                  </Link>
              </div>
            )}
            
              {/* right side items */}
              <div className="flex ml-auto items-center justify-end space-x-4 mr-4">
                {showGODview && 
                <MemberRoleButton />
                }
                  <div className='flex'>
                    <Switch
                      size="small"
                      checked={isDark}
                      // onChange={(e) => setIsDark(e.target.checked)}
                      onChange={handleChangeDarkMode}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </div>
                  {/* <MenuIcon className="navBtnMobile "/> */}
                  <ProfileDropdown user={user} /> 

              </div>
          </header>
        </div>
      </>
    )

}

export default Header
