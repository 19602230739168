import { useEffect, useState } from 'react';
import {darkModeState} from '../atoms/darkModeState'
import { useRecoilValue } from "recoil";
import { getData } from '../ApiCalls/DataApis';
import { apiLink } from '../ApiCalls/ApisVariables';
import {MemberTooltip} from '../styles/Tooltip'

function EmailTableRow({el,index}) {
    const isDark = useRecoilValue(darkModeState)
    const [seenCount, setSeenCount] = useState(0)
    const [showToolTip, setShowTooltip] = useState(false)
    const [trackingInfo, setTrackingInfo] = useState([])

    useEffect(() => {
        if(el && el.email_seen_count){
           let count = Number(el.email_seen_count)
           setSeenCount(count)

        }
    },[el])

    const handleShowToolTip = (el) => {
        getData(apiLink + `/v1/internal/emails/tracking?email_id=${el.email_sent_id}`)
            .then(async response => {
              if(response && response.ok ){
                const data = await response.json();
                if(data && data.data ){
                    setTrackingInfo(data.data)
                    setShowTooltip(true)
                }
              }
            });
    }

    const convertHtmlToString = (html) => {
        var plainString = html.replace(/<[^>]+>/g, '');

        return plainString
    
    }

    const handleDateFormat = (timestamp) => {
        if(timestamp){
            var currentDate = new Date(timestamp)
            var currentHours = currentDate.getHours();
            var currentMinutes = currentDate.getMinutes();
            var currentAmPm = currentHours >= 12 ? 'pm' : 'am';
            currentHours = currentHours % 12;
            currentHours = currentHours ? currentHours : 12; // the hour '0' should be '12'
            currentMinutes = currentMinutes < 10 ? '0'+currentMinutes : currentMinutes;
            var currentStrTime = currentHours + ':' + currentMinutes + '' + currentAmPm;
            var shortcurrentDate = currentDate.getMonth()+1 + "/" + currentDate.getDate() + "/" + currentDate.getFullYear().toString().substr(-2)
            // var currentTime = currentDate.getMonth()+1 + "/" + currentDate.getDate() + "/" + currentDate.getFullYear().toString().substr(-2) + " " + currentStrTime;
            // var startTime = startDate.getMonth()+1 + "/" + startDate.getDate() + "/" + startDate.getFullYear().toString().substr(-2) + " " + startStrTime;
            return currentStrTime + "  " + shortcurrentDate  ;
        }
    }

  return (
    <tr key={index} className='border-b-[0.01em] dark:border-slate-500 z-0'>
            {seenCount > 0 ? (
                <td className='text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 text-right'
                // onMouseEnter={() => handleShowToolTip(el)}
                // onMouseLeave={() =>  setShowTooltip(false)}
            >
                <MemberTooltip
                    placement="right"  
                    title={
                        <div className={`rounded-xl px-4 py-3 min-w-[350px] md:min-w-[400px] ${isDark ? ' bg-slate-500 ':' bg-slate-300 '}`}>
                        {el && el.email_tracking && Object.keys(el.email_tracking).length > 0 && el.email_tracking.map((userInfo,index) => (
                            <div key={`email-tracking-${index}`} className={`flex flex-col justify-between ${isDark ? ' text-white ':' text-gray-700'} text-xs`}>
                                <div className={`flex justify-between ${isDark ? ' text-white ':' text-gray-700'} text-sm font-light`}>
                                    <div className='py-0.5'>{userInfo.email_read_location ? userInfo.email_read_location : 'Unknown'}</div>
                                    <div className='py-0.5'>{handleDateFormat(userInfo.email_read_timestamp)}</div>
                                </div>
                            </div>
                        ))}
                        </div>
                    }
                >
                    <div className='flex'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`w-4 h-4 ${isDark ? ' text-blue-300 ':' text-blue-600'}`}>
                            <path fillRule="evenodd" d="M14.615 1.595a.75.75 0 01.359.852L12.982 9.75h7.268a.75.75 0 01.548 1.262l-10.5 11.25a.75.75 0 01-1.272-.71l1.992-7.302H3.75a.75.75 0 01-.548-1.262l10.5-11.25a.75.75 0 01.913-.143z" clipRule="evenodd" />
                        </svg>
                        <span className={`text-xs pl-0.5 font-normal ${isDark ? ' text-blue-300 ':' text-blue-600'}`}>{seenCount}</span>
                    </div>
                </MemberTooltip>
                {/* <div className='flex relative'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`w-4 h-4 text-blue-600 dark:text-blue-300`}>
                        <path fillRule="evenodd" d="M14.615 1.595a.75.75 0 01.359.852L12.982 9.75h7.268a.75.75 0 01.548 1.262l-10.5 11.25a.75.75 0 01-1.272-.71l1.992-7.302H3.75a.75.75 0 01-.548-1.262l10.5-11.25a.75.75 0 01.913-.143z" clipRule="evenodd" />
                    </svg>
                    <span className='text-xs pl-0.5 font-normal text-blue-600 dark:text-blue-300'>{seenCount}</span>
                    {showToolTip && 
                        <div className='absolute left-8 top-0 rounded-xl bg-slate-200 dark:bg-slate-600 px-3 py-1.5 z-50 min-w-[350px]'>
                            {trackingInfo.map((userInfo,index) => (
                                <div key={`tracking-${index}`} className='flex flex-col justify-between text-gray-600 dark:text-gray-300 text-xs'>
                                    <div className='flex justify-between text-gray-600 dark:text-gray-300 text-xs'>
                                        <div className='py-0.5'>{userInfo.email_read_location ? userInfo.email_read_location : 'Unknown'}</div>
                                        <div className='py-0.5'>{handleDateFormat(userInfo.email_read_timestamp)}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    }
                </div> */}
            </td>
            ):(
                <td className='relative text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 text-right flex'
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`w-4 h-4 text-gray-400 dark:text-gray-500`}>
                        <path fillRule="evenodd" d="M14.615 1.595a.75.75 0 01.359.852L12.982 9.75h7.268a.75.75 0 01.548 1.262l-10.5 11.25a.75.75 0 01-1.272-.71l1.992-7.302H3.75a.75.75 0 01-.548-1.262l10.5-11.25a.75.75 0 01.913-.143z" clipRule="evenodd" />
                    </svg>
                    <span className='text-xs pl-0.5 font-normal text-gray-500 dark:text-gray-400'>{seenCount}</span>
                </td>
            )}
        <td className='text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 '>
            <div className='truncate max-w-[200px]'>
                {el.email_sent_to.toString()}
            </div>
        </td>
        <td className='text-sm  px-1 py-3 '>
            <div className='flex truncate'>
                <span className='font-semibold text-gray-600 dark:text-gray-300'>{el.email_sent_subject}</span>
                <span className='truncate font-light  pl-2 text-gray-600 dark:text-gray-400'>{convertHtmlToString(el.email_sent_body_html)}</span>
            </div>
        </td>
        <td className='text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 overflow-hidden text-right'>
            {handleDateFormat(el.email_sent_timestamp)}
        </td>
    </tr>
  )
}

export default EmailTableRow