import { useParams } from 'react-router-dom'
import { darkModeState } from '../atoms/darkModeState'
import { useRecoilValue } from 'recoil'
import { useEffect, useState } from 'react'
import { getData, postData } from '../ApiCalls/DataApis'
import { apiLink } from '../ApiCalls/ApisVariables'
import { motion } from 'framer-motion'
import toast from 'react-hot-toast';
import NotesTableRow from './NotesTableRow'

function NotesTable() {
    const isDark = useRecoilValue(darkModeState)
    const { organization_uuid } = useParams()
    const [noteText, setNoteText] = useState('')
    const [notesList, setNotesList] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(organization_uuid){
            getNotes()
        }


    }, [organization_uuid])

    const getNotes = () => {
        if(organization_uuid){
            getData(apiLink + '/v1/internal/dashboard/organizations/' + organization_uuid + '/notes')
            .then(async response => {
              if(response && response.ok ){
                const data = await response.json();
                if(data && data.data){
                    // console.log('Notes',data.data)
                    setNotesList(data.data)
                } 
              }
            });
        }
    }


    const submitNote = () => {

        if(!loading){
            setLoading(true)
            postData(apiLink + '/v1/internal/dashboard/organizations/' + organization_uuid + '/notes', 
            {'note_description': noteText})
              .then(async response => {
                const data = await response.json();
                if(response.ok){
                  // const data = await response.json();
                  setNoteText('')
                  getNotes()
                  setLoading(false)
                  toast.success('Note Added')
                }
                else{
                  try{
                    const data = await response.json();
                    setLoading(false)
                    toast.error(data.message)
                  }catch (e){
                    toast.error('Something went wrong')
                    setLoading(false)
                  }
                }
              })
        }

    }


  return (
    <div>
        <div className='w-full px-2 pb-4 overflow-scroll scrollbar-hide bg-slate-50 dark:bg-slate-700 rounded-3xl h-[550px]'>
            <h1 className='w-full text-gray-600 dark:text-gray-200 font-semibold text-2xl mt-4  ml-1'>
                Customer Notes
            </h1>
            <div className='flex rounded-md mt-1 py-2 w-full'>
                <input className={`dark:hover:bg-gray-900 dark:focus:bg-gray-900 dark:bg-gray-800 dark:focus:ring-gray-500 dark:text-gray-300 dark:placeholder:text-gray-400 bg-gray-100 text-gray-600 border-gray-300 focus:ring-gray-300 focus:border-gray-300 placeholder:text-gray-500 block w-full pl-2 sm:text-sm rounded-md text-ellipsis py-1`}
                    value={noteText} 
                    onChange={(e) => setNoteText(e.target.value)} 
                    type="text" 
                    placeholder="Type note..."
                    
                />
                <div className='ml-1'>
                    <motion.button 
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className='bg-blue-500 rounded-lg px-2 h-full  text-white'
                    onClick={submitNote}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
                        </svg>
                    </motion.button>
                </div>
            </div>
            <table className="table-auto w-full ">
                {/* <thead >
                <tr className='border-b-[0.01em] dark:border-zinc-600 text-left'>
                    <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 text-left'>Notes</th>
                    <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 text-right'>Date</th>
                    <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 text-center'></th>
                </tr>
                </thead> */}
                <tbody >
                {notesList.map((note, index) => (
                  <NotesTableRow note={note} key={note.note_uuid} getNotes={getNotes} />
                ))}
                </tbody>
            </table>
        </div>
    </div>
  )
}

export default NotesTable